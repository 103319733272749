import useTranslate from "../../hooks/useTranslate";
import React from "react";



const HandiwPage = () => {
  

  const { language, setLanguage, t } = useTranslate("mn");

  return (
    <div>
      <div
        className="sigma_subheader dark-overlay dark-overlay-2"
        style={{
          backgroundImage:
            "url(https://templatebae.net/themes/html/stupa/assets/img/subheader.jpg)",
          height: "60vh",
          paddingTop: "25vh",
        }}
        // style="background-image: "
      >
        <div className="container">
          <div className="sigma_subheader-inner">
            <div className="sigma_subheader-text">
              <h1> {t("orgol")} </h1>
            </div>
           
          </div>
        </div>
      </div>

      <div className="section section-padding light-bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <div className="section-title text-end">
                
                <h4 className="title"> {t("medees")} </h4>
              </div>
            </div>
            <div className="col-md-2 d-none d-md-block">
              {" "}
              <span className="vertical-seperator"></span>{" "}
            </div>
            <div className="col-md-5 d-none d-md-block">
              <p className="fw-600 mb-0 custom-secondary">
                {" "}
                {t("handiwiin_text")}{" "}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6">
              <a href="/handiwtuhai" className="sigma_service style-3">
                <div className="sigma_service-thumb">
                  <img src="baishin.jpg" alt="img" />
                  <i className="flaticon-anahata"></i>
                </div>
                <div className="sigma_service-body">
                  <h5>{t("sum_handiw")}</h5>
                  <p>{t("sum_handiw_text")}</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="section"
        id="dans"
        
      >
        <div className="container ">
          <div
            className="row"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
              
            }}
            
          >
           
           
          </div>
        </div>
      </div>
    </div>
  );
};
export default HandiwPage;
