import useTranslate from "../../hooks/useTranslate";
const BichlegPage = () => {
  const { language, setLanguage, t } = useTranslate("mn");

  return (
    <div>
      <div
        className="sigma_subheader dark-overlay dark-overlay-2"
        style={{
          backgroundImage:
            "url(https://templatebae.net/themes/html/stupa/assets/img/subheader.jpg)",
          height: "60vh",
          paddingTop: "25vh",
        }}
        // style="background-image: "
      >
        <div className="container">
          <div className="sigma_subheader-inner">
            <div className="sigma_subheader-text">
              <h1>{t("unshlag")}</h1>
            </div>
            <nav aria-label="breadcrumb"></nav>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <div className="row sigma_broadcast-video">
            <div className="col-12 mb-5">
              <div className="row g-0 align-items-center">
                <div className="col-lg-6">
                  <div className="sigma_video-popup-wrap">
                    <img src="/women.jpg" alt="hi img" />
                    <a
                      target="blank"
                      href="https://www.youtube.com/@kunze2264/videos&t=2s"
                      className="sigma_video-popup popup-youtube"
                    >
                      <i className="fas fa-play"></i>
                    </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="sigma_box m-0">
                    <h4 className="title">{t("unshlag")}</h4>
                    <p className="m-0"></p>
                    <div className="accordion with-gap" id="generalFAQExample">
                      <div className="card">
                        <div
                          className="card-header"
                          data-bs-toggle="collapse"
                          role="button"
                          data-bs-target="#generalOne"
                          aria-expanded="true"
                          aria-controls="generalOne"
                        >
                          {t("unshlag")}
                        </div>
                        <div
                          id="generalOne"
                          className="collapse show"
                          data-bs-parent="#generalFAQExample"
                        >
                          <div className="card-body">
                            <p
                              style={{
                                color: "gray",
                              }}
                            >
                              {t("unshlag_text")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BichlegPage;
