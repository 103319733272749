import useTranslate from "../../hooks/useTranslate";
import React from "react";

const GishuudtuhaiPage = () => {
  const { language, setLanguage, t } = useTranslate("mn");
  return (
    <div>
      <div
        class="sigma_subheader dark-overlay dark-overlay-2"
        style={{
          backgroundImage:
            "url(https://templatebae.net/themes/html/stupa/assets/img/subheader.jpg)",
          height: "60vh",
          paddingTop: "25vh",
        }}
        // style="background-image: "
      >
        <div class="container">
          <div class="sigma_subheader-inner">
            <div class="sigma_subheader-text">
              <h1>{t("bagsh_tuhai_title")}</h1>
            </div>
            
          </div>
        </div>
      </div>

      <div class="section sigma_post-single">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="entry-content">
                <div class="sigma_volunteer-detail mb-5">
                  <div class="row">
                    <div class="col-lg-5">
                      <div class="sigma_member-image style-1">
                        <img src="assets/img/buddha_images/IMG-3171.JPG" class="mb-0" alt="volunteer" style={{height:"400px",}} />
                      </div>
                    </div>
                    <div class="col-lg-7">
                      <div class="sigma_volunteer-detail-inner mt-5 mt-lg-0 ps-0 ps-lg-4">
                        <h3 class="sigma_member-name">{t("terguun_name")}</h3>
                        <span class="sigma_volunteer-detail-category">
                        {t("bagsh_tuhai_title")}
                        </span>
                        <ul class="sigma_volunteer-detail-info">
                          <li>
                            <i class="fas fa-phone"></i>
                            <span class="title">{t("utas")}:</span>(+976) 9908-7125
                          </li>
                          <li>
                            <i class="fas fa-envelope"></i>
                            <span class="title">{t("e_mail")}:</span>kunze2014@gmail.com
                          </li>
                          <li>
                            <i class="fas fa-map-marker-alt"></i>
                            <span class="title">{t("hayg")}:</span>{t("Hayga")}
                          </li>
                        </ul>
                        <ul class="sigma_volunteer-detail-info">
                          <li>
                            <i class="fab fa-facebook-f"></i>
                            <span class="title">Facebook:</span> 
                            <a href="https://www.facebook.com/Bumankhand" target="blank">facebook.com/Bumankhand </a>
                          </li>

                          
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <p>
                  <GishuudItem
                  title={t("yariltslaga")}
                  id="yriltsalga"
                  >
                   <p>{t("yariltslaga_text")}<br/></p>
                   <p>{t("yariltslaga_text1")}<br/></p>
                   <p>{t("yariltslaga_text2")}<br/></p>
                   <p>{t("yariltslaga_text3")}<br/></p>
                   <p>{t("yariltslaga_text4")}<br/></p>
                   <p>{t("yariltslaga_text5")}<br/></p>
                   <p>{t("yariltslaga_text6")}</p>
                  </GishuudItem>
                  <GishuudItem
                  title={t("nom_buteel")}
                  id="yriltsalga"
                  >
                   {t("nom_buteel_text")}<br/>
                   {t("nom_buteel_text1")}<br/>
                   {t("nom_buteel_text2")}
                  
                  </GishuudItem>
                  <GishuudItem
                  title={t("uran_buteel")}
                  id="yriltsalga"
                  >
                   <p>{t("uran_text")}</p>
                   <p>{t("uran_text1")}</p>
                   <p>{t("uran_text2")}</p>
                   <p>{t("uran_text3")}</p>
                   <p>{t("uran_text4")}</p>
                   <p>{t("uran_text5")}</p>
                   <p>{t("uran_text6")}</p>
                   <p>{t("uran_text7")}</p>
                   <p>{t("uran_text8")}</p>
                   <p>{t("uran_text9")}</p>
                   <p>{t("uran_text10")}</p>
                  </GishuudItem>
                 
                </p>
                <hr />
                <h4>{t("ur_chadwar")}</h4>
                <div class="row">
                  <div class="col-lg-12">
                    <p>
                    {t("ur_chadwar_text")}
                    </p>
                    <p class="m-0"></p>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GishuudtuhaiPage;

function GishuudItem({ title, children, id }) {
  const [isShow, setIsShow] = React.useState(false);
  return (
    <div className="accordion with-gap" id={`${id}_parent`}>
      <div className="card">
        <div
          className="card-header"
          //data-bs-toggle="collapse"
          //role="button"
          //data-bs-target="#generalOne"
          //aria-expanded="true"
          //aria-controls="generalOne"
          onClick={() => setIsShow(!isShow)}
        >
          {title}
        </div>
        <div
          id={id + ""}
          className={`collapse ${isShow ? "show" : ""}`}
          data-bs-parent={`${id}_parent`}
        >
          <div className="card-body">
            <p
              style={
                {
                  // color: "gray",
                }
              }
            >
              {children}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
