import useTranslate from "../hooks/useTranslate";
export default function BlogSection() {
    const { language, setLanguage, t } = useTranslate("mn");
    return (
        <div className="section section-padding pt-0">
            <div className="container">
                <div className="section-title section-title-2 text-center">
                    <h4 className="title">{t("tosol")}</h4>
                </div>
                <div className="row">
                    {/* Article Start */}
                    <div className="col-lg-4 col-md-6">
                        <article className="sigma_post style-2">
                            <div className="sigma_post-thumb">
                                <a state={{id:1}} href="/tosol?id=1">
                                    <img src="assets/img/buddha_images/IMG_6885.JPG" alt="post" />
                                </a>
                            </div>
                            <div className="sigma_post-body">
                                <div className="sigma_post-meta">
                                    <a state={{id:1}} href="/tosol?id=1" className="sigma_post-date"> <i className="far fa-calendar" /> {t("day")}</a>
                                </div>
                                <h5> <a state={{id:1}} href="/tosol?id=1">{t("ow_ulamj_title")}</a> </h5>
                            </div>
                        </article>
                    </div>
                    {/* Article End */}
                    {/* Article Start */}
                    <div className="col-lg-4 col-md-6">
                        <article className="sigma_post style-2">
                            <div className="sigma_post-thumb">
                                <a state={{id:3}} href="/tosol?id=3">
                                    <img src="women.jpg" alt="post"  style={{height:"265px"}}/>
                                </a>
                            </div>
                            <div className="sigma_post-body">
                                <div className="sigma_post-meta">
                                    <a state={{id:3}} href="/tosol?id=3" className="sigma_post-date"> <i className="far fa-calendar" /> {t("day")}</a>
                                </div>
                                <h5> <a state={{id:3}} href="/tosol?id=3">{t("unshlag")}</a> </h5>
                            </div>
                        </article>
                    </div>
                    {/* Article End */}
                    {/* Article Start */}
                    <div className="col-lg-4 col-md-6">
                        <article className="sigma_post style-2">
                            <div className="sigma_post-thumb">
                                <a state={{id:2}} href="/tosol?id=2">
                                    <img src="tosol_back.jpg" alt="post" style={{height:"265px"}} />
                                </a>
                            </div>
                            <div className="sigma_post-body">
                                <div className="sigma_post-meta">
                                    <a state={{id:2}} href="/tosol?id=2" className="sigma_post-date"> <i className="far fa-calendar" /> {t("tosol_day")}</a>
                                </div>
                                <h5> <a state={{id:2}} href="/tosol?id=2">{t("tosol")} 2023/06/03</a> </h5>
                            </div>
                        </article>
                    </div>
                    {/* Article End */}
                </div>
            </div>
        </div>

    )
}