import React, { useState } from "react";
import useTranslate from "../hooks/useTranslate";
import LanguageSwitch from "./LanguageSwitch";

export default function Header() {
  const [open, setOpen] = useState(false);
  const [aboutUs, setAboutUs] = useState(false);
  const [news, setNews] = useState(false);
  const [sing, setSing] = useState(false);
  const [donation, setDonation] = useState(false);
  const { language, setLanguage, t } = useTranslate("mn");

  return (
    <React.Fragment>
      <div
        className="modal fade sigma_quick-view-modal"
        id="quickViewModal"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body">
              <div
                className="close-btn close-dark close"
                data-bs-dismiss="modal"
              >
                <span />
                <span />
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="sigma_product-single-thumb">
                    <img src="assets/img/products/new/1.jpg" alt="product" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="sigma_product-single-content">
                    <h4 className="entry-title">
                      {" "}
                      Koobay 14" Wooden Trousers Bottom Clips Hangers w Rose
                      Gold.{" "}
                    </h4>
                    <div className="sigma_product-price">
                      <span>352$</span>
                      <span>245$</span>
                    </div>
                    <div className="sigma_rating-wrapper">
                      <div className="sigma_rating">
                        <i className="far fa-star active" />
                        <i className="far fa-star active" />
                        <i className="far fa-star active" />
                        <i className="far fa-star active" />
                        <i className="far fa-star" />
                      </div>
                      <span>255 Reviews</span>
                    </div>
                    <p>
                      {" "}
                      <strong className="me-2">
                        Interested: <span>05</span>
                      </strong>{" "}
                      <strong>
                        Availablity: <span>In Stock</span>
                      </strong>{" "}
                    </p>
                    <p className="sigma_product-excerpt">
                      Powerful breathing techniques and wisdom that will
                      transform your life. Equip yourself with practical
                      knowledge.
                    </p>
                    <form className="sigma_product-atc-form">
                      <div className="sigma_product-variation-wrapper">
                        <div className="sigma_product-radio form-group">
                          <label>
                            <input
                              type="radio"
                              name="size"
                              defaultValue
                              defaultChecked
                            />
                            <span>XS</span>
                          </label>
                          <label>
                            <input type="radio" name="size" defaultValue />
                            <span>S</span>
                          </label>
                          <label>
                            <input type="radio" name="size" defaultValue />
                            <span>M</span>
                          </label>
                          <label>
                            <input type="radio" name="size" defaultValue />
                            <span>L</span>
                          </label>
                          <label>
                            <input type="radio" name="size" defaultValue />
                            <span>XL</span>
                          </label>
                        </div>
                      </div>
                      <div className="qty-outter">
                        <a
                          href="product-single.html"
                          className="sigma_btn-custom secondary"
                        >
                          Buy Now
                        </a>
                        <div className="qty-inner">
                          <h6>Qty:</h6>
                          <div className="qty">
                            <span className="qty-subtract">
                              <i className="fa fa-minus" />
                            </span>
                            <input type="text" name="qty" defaultValue={1} />
                            <span className="qty-add">
                              <i className="fa fa-plus" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="sigma_post-single-meta">
                      <div className="sigma_post-single-meta-item sigma_post-share">
                        <h6>Share</h6>
                        <ul className="sigma_sm">
                          <li>
                            <a href="#">
                              <i className="fab fa-facebook-f" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fab fa-linkedin-in" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fab fa-twitter" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fab fa-youtube" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="sigma_post-single-meta-item">
                        <div className="sigma_product-controls">
                          <a href="#" data-toggle="tooltip" title="Compare">
                            {" "}
                            <i className="far fa-signal-4" />{" "}
                          </a>
                          <a href="#" data-toggle="tooltip" title="Wishlist">
                            {" "}
                            <i className="far fa-heart" />{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <aside className="sigma_aside sigma_aside-right sigma_aside-right-panel sigma_aside-bg">
        <div className="sidebar">
          <div className="sidebar-widget widget-logo">
            <img src="assets/img/logo.png" className="mb-30" alt="img" />
            <p>
              Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.
              Donec rutrum congue leo eget malesuada. Praesent sapien massa,
              convallis a pellentesque nec, egestas non nisi.
            </p>
          </div>
          <div className="sidebar-widget widget-ig">
            <h5 className="widget-title">Instagram</h5>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                <a href="#" className="sigma_ig-item">
                  <img src="assets/img/ig/1.jpg" alt="ig" />
                </a>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                <a href="#" className="sigma_ig-item">
                  <img src="assets/img/ig/2.jpg" alt="ig" />
                </a>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                <a href="#" className="sigma_ig-item">
                  <img src="assets/img/ig/3.jpg" alt="ig" />
                </a>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                <a href="#" className="sigma_ig-item">
                  <img src="assets/img/ig/4.jpg" alt="ig" />
                </a>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                <a href="#" className="sigma_ig-item">
                  <img src="assets/img/ig/5.jpg" alt="ig" />
                </a>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                <a href="#" className="sigma_ig-item">
                  <img src="assets/img/ig/6.jpg" alt="ig" />
                </a>
              </div>
            </div>
          </div>
          <div className="sidebar-widget">
            <h5 className="widget-title">Follow Us</h5>
            <div className="sigma_post-share">
              <ul className="sigma_sm">
                <li>
                  <a href="#" className="sigma_round-button">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="125px"
                      height="125px"
                      viewBox="0 0 197 197"
                      enableBackground="new 0 0 197 197"
                      xmlSpace="preserve"
                    >
                      <circle
                        className="sigma_round-button-stroke"
                        strokeLinecap="round"
                        cx="98.5"
                        cy="98.6"
                        r="97.5"
                      />
                      <circle
                        className="sigma_round-button-circle"
                        strokeLinecap="round"
                        cx="98.5"
                        cy="98.6"
                        r="97.5"
                      />
                    </svg>
                    <i className="fab fa-facebook-f" />
                  </a>
                </li>
                <li>
                  <a href="#" className="sigma_round-button">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="125px"
                      height="125px"
                      viewBox="0 0 197 197"
                      enableBackground="new 0 0 197 197"
                      xmlSpace="preserve"
                    >
                      <circle
                        className="sigma_round-button-stroke"
                        strokeLinecap="round"
                        cx="98.5"
                        cy="98.6"
                        r="97.5"
                      />
                      <circle
                        className="sigma_round-button-circle"
                        strokeLinecap="round"
                        cx="98.5"
                        cy="98.6"
                        r="97.5"
                      />
                    </svg>
                    <i className="fab fa-linkedin-in" />
                  </a>
                </li>
                <li>
                  <a href="#" className="sigma_round-button">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="125px"
                      height="125px"
                      viewBox="0 0 197 197"
                      enableBackground="new 0 0 197 197"
                      xmlSpace="preserve"
                    >
                      <circle
                        className="sigma_round-button-stroke"
                        strokeLinecap="round"
                        cx="98.5"
                        cy="98.6"
                        r="97.5"
                      />
                      <circle
                        className="sigma_round-button-circle"
                        strokeLinecap="round"
                        cx="98.5"
                        cy="98.6"
                        r="97.5"
                      />
                    </svg>
                    <i className="fab fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="#" className="sigma_round-button">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="125px"
                      height="125px"
                      viewBox="0 0 197 197"
                      enableBackground="new 0 0 197 197"
                      xmlSpace="preserve"
                    >
                      <circle
                        className="sigma_round-button-stroke"
                        strokeLinecap="round"
                        cx="98.5"
                        cy="98.6"
                        r="97.5"
                      />
                      <circle
                        className="sigma_round-button-circle"
                        strokeLinecap="round"
                        cx="98.5"
                        cy="98.6"
                        r="97.5"
                      />
                    </svg>
                    <i className="fab fa-youtube" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </aside>
      <div className="sigma_aside-overlay aside-trigger-right" />
      <aside className={`sigma_aside sigma_aside-left ${open ? "open" : ""}`}>
        <div>
          <a className="navbar-brand" href="/">
            {" "}
            <img src="BumanHand.jpg" alt="logo" />{" "}
          </a>
          <div className="px-2 py-2">
            <LanguageSwitch />
          </div>
        </div>

        <ul className="navbar-nav">
          <li className="menu-item menu-item-has-children">
            <a href="/">{t("nuur")}</a>
            <ul className="sub-menu"></ul>
          </li>
          <li className="menu-item menu-item-has-children">
            <a href="/about">{t("bidnii")}</a>
            <ul className="sub-menu"></ul>
          </li>
          <li className="menu-item menu-item-has-children">
            <a href="/bagsh">{t("bagsh")}</a>
            <ul className="sub-menu"></ul>
          </li>
          <li className="menu-item">
            <a href="/gishuud">{t("gishuud")}</a>
          </li>
          <li className="menu-item">
            <a href="/zurag">{t("zurag")}</a>
          </li>
          <li className="menu-item menu-item-has-children">
            <a onClick={() => setNews(!news)}>{t("medee")}</a>
            <ul
              className="sub-menu"
              style={{
                height: news ? "100px" : "0px",
                display: "block",
                overflow: "hidden",
                transition: "all 0.2s ease-in",
              }}
            >
              <li className="menu-item">
                <a href="/medeeD">{t("Medee")}</a>
              </li>
              <li className="menu-item">
                {" "}
                <a href="/tosol">{t("tosol")}</a>{" "}
              </li>
            </ul>
          </li>
          <li className="menu-item menu-item-has-children">
            <a onClick={() => setSing(!sing)}>{t("unshlag")}</a>
            <ul
              className="sub-menu"
              style={{
                height: sing ? "50px" : "0px",
                display: "block",
                overflow: "hidden",
                transition: "all 0.2s ease-in",
              }}
            >
              <li className="menu-item">
                {" "}
                <a href="/bichleg">{t("bichleg")}</a>{" "}
              </li>
            </ul>
          </li>

          <li className="menu-item menu-item-has-children">
            <a href="/surgalt">{t("surgalt")}</a>
            <ul className="sub-menu"></ul>
          </li>
          <li className="menu-item menu-item-has-children">
            <a href="/handiw">{t("orgol")}</a>
            <ul className="sub-menu"></ul>
          </li>
          <li className="menu-item">
            <a href="/handiwtuhai">{t("handiw")}</a>
          </li>
          <li className="menu-item">
            <a href="/contact">{t("holboo")}</a>
          </li>
        </ul>
      </aside>
      <div
        className="sigma_aside-overlay aside-trigger-left"
        onClick={() => setOpen(false)}
      />

      <header className="sigma_header header-3 can-sticky header-absolute">
        <div className="sigma_header-middle">
          <nav className="navbar position-fixed bg-white navbar-width">
            <div className="sigma_logo-wrapper">
              <a className="navbar-brand" href="/">
                <img src="BumanHand.jpg" alt="logo" />
              </a>
            </div>
            <div className="sigma_header-inner">
              <div className="sigma_header-top">
                <div className="sigma_header-top-inner">
                  <ul className="sigma_header-top-links">
                    <li className="menu-item">
                      {" "}
                      <a href="tel:+99087125">
                        {" "}
                        <i className="fal fa-phone" /> (+976) 99087125
                      </a>{" "}
                    </li>
                    <li className="menu-item">
                      {" "}
                      <a target="blank" href="https://mail.google.com/mail">
                        {" "}
                        <i className="fal fa-envelope" /> info@buddhistwomen.mn
                      </a>{" "}
                    </li>
                  </ul>
                  <ul className="navbar-nav"></ul>
                  <ul className="sigma_header-top-links">
                    <li className="menu-item menu-item-has-children d-flex align-items-center">
                      {/* {language === "mn" ? (
                        <img
                          src="/mongolia.png"
                          alt="img"
                          style={{
                            height: "20px",
                          }}
                        />
                      ) : (
                        <img
                          src="/us-flag.png"
                          alt="img"
                          style={{
                            height: "20px",
                          }}
                        />
                      )}
                      <a href="#" className="m-0">
                        {" "}
                        {t("language")}
                      </a>
                      <ul className="sub-menu sub-menu-left">
                        <li>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => setLanguage("mn")}
                          >
                            Монгол
                          </a>
                        </li>
                        <li>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => setLanguage("en")}
                          >
                            English
                          </a>
                        </li>
                      </ul> */}
                      <LanguageSwitch />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="d-flex justify-content-center justify-content-lg-between">
                <ul className="navbar-nav">
                  <li className="menu-item menu-item-has-children">
                    <a href="/">{t("nuur")}</a>
                    <ul className="sub-menu"></ul>
                  </li>

                  <li className="menu-item menu-item-has-children">
                    <a href="/about">{t("bidnii")}</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        {" "}
                        <a href="/bagsh">{t("bagsh")}</a>{" "}
                      </li>
                      <li className="menu-item">
                        {" "}
                        <a href="/gishuud">{t("gishuud")}</a>{" "}
                      </li>
                      <li className="menu-item">
                        {" "}
                        <a href="/zurag">{t("zurag")}</a>{" "}
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="/medeeD">{t("medee")}</a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        {" "}
                        <a href="/tosol">{t("tosol")}</a>{" "}
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="/bichleg">{t("unshlag")}</a>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="/surgalt">{t("surgalt")}</a>
                    <ul className="sub-menu"></ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <a href="/handiw">{t("orgol")}</a>
                    <ul className="sub-menu"></ul>
                  </li>
                  <li className="menu-item">
                    <a href="/contact">{t("holboo")}</a>
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="sigma_header-controls style-2"
              onClick={() => setOpen(true)}
            >
              <a href="/golomt" className="sigma_btn-custom">
                {t("orgol")}
              </a>
              <ul className="sigma_header-controls-inner">
                <li className="aside-toggler style-2 aside-trigger-left">
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </React.Fragment>
  );
}
