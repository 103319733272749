import useTranslate from "../../hooks/useTranslate";
import React from "react";

const Gishuun4Page = () => {
  const { language, setLanguage, t } = useTranslate("mn");
  return (
    <div>
      <div
        class="sigma_subheader dark-overlay dark-overlay-2"
        style={{
          backgroundImage:
            "url(https://templatebae.net/themes/html/stupa/assets/img/subheader.jpg)",
          height: "60vh",
          paddingTop: "25vh",
        }}
        // style="background-image: "
      >
        <div class="container">
          <div class="sigma_subheader-inner">
            <div class="sigma_subheader-text">
              <h1>{t("gishuuns")}</h1>
            </div>
          </div>
        </div>
      </div>

      <div class="section sigma_post-single">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="entry-content">
                <div class="sigma_volunteer-detail mb-5">
                  <div class="row">
                    <div class="col-lg-5">
                      <div class="sigma_member-image style-1">
                        <img
                          src="gishuun4.jpg"
                          class="mb-0"
                          alt="volunteer"
                          style={{ height: "400px", width: "500px" }}
                        />
                      </div>
                    </div>
                    <div class="col-lg-7">
                      <div class="sigma_volunteer-detail-inner mt-5 mt-lg-0 ps-0 ps-lg-4">
                        <h3 class="sigma_member-name">
                          {t("g_javz")}
                          {t("javz")}
                        </h3>
                        <span class="sigma_volunteer-detail-category">
                          {t("gishuuns")}
                        </span>
                        <ul class="sigma_volunteer-detail-info">
                          <li>
                            <i class="fas fa-phone"></i>
                            <span class="title">{t("Огноо")}:</span>{" "}
                            {t("holboo_elssen2")}
                          </li>
                          <li>
                            <i class="fas fa-envelope"></i>
                            <span class="title">{t("Мэргэжил")}:</span>
                            {t("J_meregjil")}
                          </li>
                          <li>
                            <i class="fas fa-map-marker-alt"></i>
                            <span class="title">{t("utas")}:</span>(+976)
                            ********
                          </li>
                        </ul>
                        <ul class="sigma_volunteer-detail-info">
                          <li>
                            <i class="fab fa-facebook-f"></i>
                            <span class="title">Facebook:</span>
                            <a
                              href="https://www.facebook.com/Bumankhand"
                              target="blank"
                            >
                              {" "}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Gishuun4Page;
