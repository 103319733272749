import medeeS from "./medeeS.css";
import styles from "./medee.module.css";
import useTranslate from "../../hooks/useTranslate";
import { useLocation } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import React from "react";

const MedeeDPage = () => {
  const location = useLocation();
  const query = useQuery();
  const [id, setId] = React.useState(
    isNaN(parseInt(query.get("id"))) ? 18 : parseInt(query.get("id"))
  );
  console.log(id);

  const { language, setLanguage, t } = useTranslate("mn");

  return (
    <div>
      <div
        className="sigma_subheader dark-overlay dark-overlay-2"
        style={{
          backgroundImage:
            "url(https://templatebae.net/themes/html/stupa/assets/img/subheader.jpg)",
          height: "60vh",
          paddingTop: "25vh",
        }}
      //style="background-image: "
      >
        <div className="container">
          <div className="sigma_subheader-inner">
            <div className="sigma_subheader-text">
              <h1>{t("Medee")}</h1>
            </div>
          </div>
        </div>
      </div>

      <section className="section">
        <div className="container">
          <div className="row ">
            <div
              className="medeeD "
              style={{
                display: "flex",
              }}
            >
              <div className="tab-content " id="myTabContent">
                <div
                  className={"tab-pane fade " + (id === 4 ? "show active" : "")}
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="section sigma_post-single">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="post-detail-wrapper">
                            <div className="entry-content">
                              <div className="sigma_post-meta">
                                <div className="sigma_post-categories">
                                  <a className="sigma_post-category">
                                    {t("medee")}
                                  </a>
                                </div>
                              </div>
                              <h4 className="entry-title">{t("buddha")} </h4>
                              <div className="sigma_post-meta">
                                <a href="#"> {t("buddha_day")}</a>
                              </div>
                              <p>{t("buddha_text")}</p>

                              <a
                                href="/odriin_bayr2.jpg"
                                className="gallery-thumb"
                              >
                                <div className={`${styles.medeeimg}`} />
                              </a>

                              <hr />

                              <p>{t("buddha_text1")}</p>

                              <p>{t("buddha_text2")}</p>
                            </div>

                            <div className="section"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={"tab-pane fade " + (id === 2 ? "show active" : "")}
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div className="section sigma_post-single">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="post-detail-wrapper">
                            <div className="entry-content">
                              <div className="sigma_post-meta">
                                <div className="sigma_post-categories">
                                  <a className="sigma_post-category">
                                    {t("medee")}
                                  </a>
                                </div>
                              </div>
                              <h4 className="entry-title">
                                {t("sum_hiid_text")}{" "}
                              </h4>
                              <div className="sigma_post-meta">
                                <a href="#"> {t("sum_hiid_day")}</a>
                              </div>
                              <p></p>
                              <h5>{t("talarhal")}</h5>
                              <p>{t("talarhal_text")}</p>
                              <a className="gallery-thumb">
                                <div
                                  className={`${styles.imgS}`}
                                  alt="post"
                                ></div>
                              </a>
                              <p>{t("talarhal_text1")}</p>
                              <hr />
                              <h4></h4>
                              <p>
                                {t("talarhal_text2")} <br />
                              </p>

                              <p></p>
                            </div>

                            <div className="section"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={"tab-pane fade " + (id === 3 ? "show active" : "")}
                  id="contact"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                >
                  <div className="section sigma_post-single">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="post-detail-wrapper">
                            <div className="entry-content">
                              <div className="sigma_post-meta">
                                <div className="sigma_post-categories">
                                  <a className="sigma_post-category">
                                    {t("medee")}
                                  </a>
                                </div>
                              </div>
                              <h4 className="entry-title">
                                {t("news_title")}{" "}
                              </h4>
                              <div className="sigma_post-meta">
                                <a href="#">
                                  {" "}
                                  <i className="far fa-calendar"></i>
                                  {t("news_ognoo")}
                                </a>
                              </div>
                              <h5 style={{ fontSize: "22px" }}>
                                {t("news_body_title")}
                              </h5>
                              <p>{t("news_header_title")}</p>
                              <a className="gallery-thumb">
                                <div
                                  className={`${styles.news}`}
                                  alt="post"
                                ></div>
                              </a>
                              <p>{t("news_body_text")}</p>
                              <hr />
                              <p>{t("news_body_text1")}</p>
                            </div>

                            <div className="section"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={"tab-pane fade " + (id === 5 ? "show active" : "")}
                  id="last"
                  role="tabpanel"
                  aria-labelledby="last-tab"
                >
                  <div className="section sigma_post-single">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="post-detail-wrapper">
                            <div className="entry-content">
                              <div className="sigma_post-meta">
                                <div className="sigma_post-categories">
                                  <a className="sigma_post-category">
                                    {t("medee")}
                                  </a>
                                </div>
                              </div>
                              <h4 className="entry-title">
                                {t("news_title_4")}{" "}
                              </h4>
                              <div className="sigma_post-meta">
                                <a href="#">
                                  {" "}
                                  <i className="far fa-calendar"></i>
                                  {t("news_4_day")}
                                </a>
                              </div>
                              <h5 style={{ fontSize: "22px" }}>
                                {t("news_bodytitle_4")}
                              </h5>
                              <p>{t("news_4_body1")}</p>
                              <a className="gallery-thumb">
                                <div
                                  className={`${styles.img4}`}
                                  alt="post"
                                ></div>
                              </a>
                              <p>{t("news_4_body2")}</p>
                              <hr />
                              <p></p>
                            </div>

                            <div className="section"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={"tab-pane fade " + (id === 6 ? "show active" : "")}
                  id="hatan"
                  role="tabpanel"
                  aria-labelledby="hatan-tab"
                >
                  <div className="section sigma_post-single">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="post-detail-wrapper">
                            <div className="entry-content">
                              <div className="sigma_post-meta">
                                <div className="sigma_post-categories">
                                  <a className="sigma_post-category">
                                    {t("medee")}
                                  </a>
                                </div>
                              </div>
                              <h4 className="entry-title">
                                {t("news_title_hatan")}{" "}
                              </h4>
                              <div className="sigma_post-meta">
                                <a href="#">
                                  {" "}
                                  <i className="far fa-calendar"></i>
                                  {t("news_day_hatan")}
                                </a>
                              </div>
                              <h5 style={{ fontSize: "22px" }}>
                                {t("news_head_hatan")}
                              </h5>
                              <p>{t("news_1text_hatan")}</p>
                              <a className="gallery-thumb">
                                <div
                                  className={`${styles.imghatan}`}
                                  alt="post"
                                ></div>
                              </a>
                              <p>{t("news_2text_hatan")}</p>
                              <hr />
                              <p>{t("news_3text_hatan")}</p>
                            </div>

                            <div className="section"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={"tab-pane fade " + (id === 7 ? "show active" : "")}
                  id="handmaa"
                  role="tabpanel"
                  aria-labelledby="handmaa-tab"
                >
                  <div className="section sigma_post-single">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="post-detail-wrapper">
                            <div className="entry-content">
                              <div className="sigma_post-meta">
                                <div className="sigma_post-categories">
                                  <a className="sigma_post-category">
                                    {t("medee")}
                                  </a>
                                </div>
                              </div>
                              <h4 className="entry-title">
                                {t("news_title_handmaa")}{" "}
                              </h4>
                              <div className="sigma_post-meta">
                                <a href="#">
                                  {" "}
                                  <i className="far fa-calendar"></i>
                                  {t("news_day_handmaa")}
                                </a>
                              </div>
                              <h5 style={{ fontSize: "22px" }}>
                                {t("news_head_handmaa")}
                              </h5>
                              <p>{t("news_1text_handmaa")}</p>
                              <a className="gallery-thumb">
                                <div
                                  className={`${styles.imghandmaa}`}
                                  alt="post"
                                ></div>
                              </a>
                              <p>{t("news_2text_handmaa")}</p>
                              <p>{t("news_2_1")}</p>
                              <p>{t("news_2_2")}</p>
                              <p>{t("news_2_3")}</p>
                              <p>{t("news_3_1")}</p>
                              <p>{t("news_3_2")}</p>
                              <p>{t("news_3_3")}</p>
                              <hr />
                              <p>{t("news_3text_handmaa")}</p>
                            </div>

                            <div className="section"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={"tab-pane fade " + (id === 8 ? "show active" : "")}
                  id="jado"
                  role="tabpanel"
                  aria-labelledby="jado-tab"
                >
                  <div className="section sigma_post-single">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="post-detail-wrapper">
                            <div className="entry-content">
                              <div className="sigma_post-meta">
                                <div className="sigma_post-categories">
                                  <a className="sigma_post-category">
                                    {t("medee")}
                                  </a>
                                </div>
                              </div>
                              <h4 className="entry-title">
                                {t("news_title_jado")}{" "}
                              </h4>
                              <div className="sigma_post-meta">
                                <a href="#">
                                  {" "}
                                  <i className="far fa-calendar"></i>
                                  {t("news_day_jado")}
                                </a>
                              </div>
                              <h5 style={{ fontSize: "22px" }}>
                                {t("news_head_jado")}
                              </h5>
                              <p>{t("news_1text_jado")}</p>
                              <a className="gallery-thumb">
                                <div
                                  className={`${styles.imgjado}`}
                                  alt="post"
                                ></div>
                              </a>
                              <p>{t("news_2text_jado")}</p>

                              <hr />
                              <p>{t("news_3text_jado")}</p>
                              <p>{t("news_2_1_jado")}</p>
                              <p>{t("news_2_2_jado")}</p>
                            </div>

                            <div className="section"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={"tab-pane fade " + (id === 9 ? "show active" : "")}
                  id="surgalt"
                  role="tabpanel"
                  aria-labelledby="surgalt-tab"
                >
                  <div className="section sigma_post-single">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="post-detail-wrapper">
                            <div className="entry-content">
                              <div className="sigma_post-meta">
                                <div className="sigma_post-categories">
                                  <a className="sigma_post-category">
                                    {t("medee")}
                                  </a>
                                </div>
                              </div>
                              <h4 className="entry-title">
                                {t("news_title_surgalt")}{" "}
                              </h4>
                              <div className="sigma_post-meta">
                                <a href="#">
                                  {" "}
                                  <i className="far fa-calendar"></i>
                                  {t("news_day_surgalt")}
                                </a>
                              </div>
                              <h5 style={{ fontSize: "22px" }}>
                                {t("news_head_surgalt")}
                              </h5>
                              <h5>{t("news_mt_surgalt")} </h5>
                              <h6 style={{ marginTop: "40px" }}>
                                {t("news_st_surgalt")}{" "}
                              </h6>
                              <p>{t("news_1text_surgalt")}</p>
                              <a className="gallery-thumb">
                                <div
                                  className={`${styles.imgsurgalt}`}
                                  alt="post"
                                ></div>
                              </a>
                              <h6>{t("news_st1_surgalt")} </h6>

                              <p>{t("news_2text_surgalt")}</p>

                              <hr />
                              <h6>{t("news_st2_surgalt")} </h6>

                              <p>{t("news_3text_surgalt")}</p>
                              <h6>{t("news_st3_surgalt")} </h6>
                              <h6>{t("news_surgalt_text1")} </h6>
                              <p>{t("news_surgalt_text2")} </p>
                              <p>{t("news_surgalt_text3")} </p>
                              <p>{t("news_surgalt_text4")} </p>
                              <p>{t("news_surgalt_text5")} </p>
                              <p>{t("news_surgalt_text6")} </p>
                              <p>{t("news_surgalt_text7")} </p>
                              <p>{t("news_surgalt_text8")} </p>
                              <p>{t("news_surgalt_text9")} </p>
                              <p>{t("news_surgalt_text10")} </p>
                              <p>{t("news_surgalt_text11")} </p>
                              <p>{t("news_surgalt_text12")} </p>
                            </div>

                            <div className="section"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    "tab-pane fade " + (id === 10 ? "show active" : "")
                  }
                  id="meeting"
                  role="tabpanel"
                  aria-labelledby="meeting-tab"
                >
                  <div className="section sigma_post-single">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="post-detail-wrapper">
                            <div className="entry-content">
                              <div className="sigma_post-meta">
                                <div className="sigma_post-categories">
                                  <a className="sigma_post-category">
                                    {t("medee")}
                                  </a>
                                </div>
                              </div>
                              <h4 className="entry-title">
                                {t("news_st_meet")}{" "}
                              </h4>
                              <div className="sigma_post-meta">
                                <a href="#">
                                  {" "}
                                  <i className="far fa-calendar"></i>
                                  {t("news_day_meeting")}
                                </a>
                              </div>
                              <h5 style={{ fontSize: "22px" }}>
                                {t("news_meeting_footer")}
                              </h5>
                              <h5> </h5>
                              <h6 style={{ marginTop: "40px" }}>
                                {t("news_st_meeting")}{" "}
                              </h6>

                              <p>{t("news_1text_1_meeting")}</p>
                              <p>{t("news_1text_2_meeting")}</p>
                              <p>{t("news_1text_3_meeting")}</p>
                              <a className="gallery-thumb">
                                <div
                                  className={`${styles.imgmeeting}`}
                                  alt="post"
                                ></div>
                              </a>
                              <h6>{t("news_st1_meeting")} </h6>

                              <p>{t("news_2text_1_meeting")}</p>
                              <p>{t("news_2text_2_meeting")}</p>
                              <p>{t("news_2text_3_meeting")}</p>

                              <hr />
                              <p>{t("news_meeting_footer")}</p>
                            </div>

                            <div className="section"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    "tab-pane fade " + (id === 11 ? "show active" : "")
                  }
                  id="ticket"
                  role="tabpanel"
                  aria-labelledby="ticket-tab"
                >
                  <div className="section sigma_post-single">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="post-detail-wrapper">
                            <div className="entry-content">
                              <div className="sigma_post-meta">
                                <div className="sigma_post-categories">
                                  <a className="sigma_post-category">
                                    {t("medee")}
                                  </a>
                                </div>
                              </div>
                              <h4 className="entry-title">
                                {t("ticket_title")}{" "}
                              </h4>
                              <div className="sigma_post-meta">
                                <a href="#">
                                  {" "}
                                  <i className="far fa-calendar"></i>
                                  {t("ticket_day")}
                                </a>
                              </div>
                              <h5 style={{ fontSize: "22px" }}>
                                {t("ticket_footer")}
                              </h5>

                              <p>{t("ticket_text")}</p>
                              <p>{t("ticket_text1")}</p>
                              <p>{t("ticket_text2")}</p>
                              <a className="gallery-thumb">
                                <div
                                  className={`${styles.imgunshilaga}`}
                                  alt="post"
                                ></div>
                              </a>

                              <p>{t("ticket_text3")}</p>
                              <p>{t("ticket_text4")}</p>
                              <p>{t("ticket_text5")}</p>

                              <hr />
                              <p>{t("ticket_footer")}</p>
                            </div>

                            <div className="section"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={"tab-pane fade " + (id === 1 ? "show active" : "")}
                  id="surgalt_2024"
                  role="tabpanel"
                  aria-labelledby="surgalt_2024-tab"
                >
                  <div className="section sigma_post-single">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="post-detail-wrapper">
                            <div className="entry-content">
                              <div className="sigma_post-meta">
                                <div className="sigma_post-categories">
                                  <a className="sigma_post-category">
                                    {t("medee")}
                                  </a>
                                </div>
                              </div>
                              <h4 className="entry-title">
                                {t("surgalt_2024_title")}{" "}
                              </h4>
                              <div className="sigma_post-meta">
                                <a href="#">
                                  {" "}
                                  <i className="far fa-calendar"></i>
                                  {t("surgalt_2024_day")}
                                </a>
                              </div>
                              <h5 style={{ fontSize: "22px" }}>
                                {t("surgalt_2024_text")}
                              </h5>

                              <p>{t("surgalt_2024_text1")}</p>
                              <p>{t("surgalt_2024_text2")}</p>
                              <p>{t("surgalt_2024_text3")}</p>
                              <p>{t("surgalt_2024_text4")}</p>
                              <a className="gallery-thumb">
                                <div
                                  className={`${styles.imgsurgalt2024}`}
                                  alt="post"
                                ></div>
                              </a>

                              <p>{t("surgalt_2024_text5")}</p>
                              <p>{t("surgalt_2024_text6")}</p>
                              <p>{t("surgalt_2024_text7")}</p>
                              <p>{t("surgalt_2024_text8")}</p>
                              <p>{t("surgalt_2024_text9")}</p>
                              <p>{t("surgalt_2024_text10")}</p>
                              <p>{t("surgalt_2024_text11")}</p>

                              <hr />
                              <p>{t("ticket_footer")}</p>
                            </div>

                            <div className="section"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div
                  className={"tab-pane fade " + (id === 12 ? "show active" : "")}
                  id="news_2024_02_7_01"
                  role="tabpanel"
                  aria-labelledby="news_2024_02_7_01-tab"
                >
                  <div className="section sigma_post-single">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="post-detail-wrapper">
                            <div className="entry-content">
                              <div className="sigma_post-meta">
                                <div className="sigma_post-categories">
                                  <a className="sigma_post-category">
                                    {t("medee")}
                                  </a>
                                </div>
                              </div>
                              <h4 className="entry-title">
                                {t("news_2024_02_7_01_title")}{" "}
                              </h4>

                              <a
                                className="gallery-thumb"
                              >
                                <img src="/tsuvral_3.jpeg" style={{width:"100%"}} height="auto"/>
                              </a>

                              <p>
                                {
                                  t("news_2024_02_7_01_text_1")
                                }
                              </p>

                              <p>
                                {
                                  t("news_2024_02_7_01_text_2")
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={"tab-pane fade " + (id === 1 ? "show active" : "")}
                  id="news_2024_02_8_01"
                  role="tabpanel"
                  aria-labelledby="news_2024_02_8_01-tab"
                >
                  <div className="section sigma_post-single">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="post-detail-wrapper">
                            <div className="entry-content">
                              <div className="sigma_post-meta">
                                <div className="sigma_post-categories">
                                  <a className="sigma_post-category">
                                    {t("medee")}
                                  </a>
                                </div>
                              </div>
                              <h4 className="entry-title">
                                {t("news_2024_02_8_01_title")}{" "}
                              </h4>

                              <a
                                className="gallery-thumb"
                              >
                                <img src="/surgalt1.jpeg" style={{width:"100%"}} height="auto"/>
                              </a>

                              <p>
                                {
                                  t("news_2024_02_7_01_text_1")
                                }
                              </p>

                              <p>
                                {
                                  t("news_2024_02_7_01_text_2")
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div
                  className={"tab-pane fade " + (id === 14 ? "show active" : "")}
                  id="news_2024_02_8_02"
                  role="tabpanel"
                  aria-labelledby="news_2024_02_8_02-tab"
                >
                  <div className="section sigma_post-single">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="post-detail-wrapper">
                            <div className="entry-content">
                              <div className="sigma_post-meta">
                                <div className="sigma_post-categories">
                                  <a className="sigma_post-category">
                                    {t("medee")}
                                  </a>
                                </div>
                              </div>
                              <h4 className="entry-title">
                                {t("news_2024_02_8_02_title")}{" "}
                              </h4>

                              <a
                                className="gallery-thumb"
                              >
                                <img src="/surgalt5.jpeg" style={{width:"100%"}} height="auto"/>
                              </a>

                              <p>
                                {
                                  t("news_2024_02_8_02_text_1")
                                }
                              </p>

                              <p>
                                {
                                  t("news_2024_02_8_02_text_2")
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div
                  className={"tab-pane fade " + (id === 15 ? "show active" : "")}
                  id="news_2024_02_8_03"
                  role="tabpanel"
                  aria-labelledby="news_2024_02_8_03-tab"
                >
                  <div className="section sigma_post-single">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="post-detail-wrapper">
                            <div className="entry-content">
                              <div className="sigma_post-meta">
                                <div className="sigma_post-categories">
                                  <a className="sigma_post-category">
                                    {t("medee")}
                                  </a>
                                </div>
                              </div>
                              <h4 className="entry-title">
                                {t("news_2024_02_8_03_title")}{" "}
                              </h4>

                              <a
                                className="gallery-thumb"
                              >
                                <img src="/surgalt33.jpeg" style={{width:"100%"}} height="auto"/>
                              </a>

                              <p>
                                {
                                  t("news_2024_02_8_03_text_1")
                                }
                              </p>

                              <p>
                                {
                                  t("news_2024_02_8_03_text_2")
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



                <div
                  className={"tab-pane fade " + (id === 16 ? "show active" : "")}
                  id="news_2024_02_8_04"
                  role="tabpanel"
                  aria-labelledby="news_2024_02_8_04-tab"
                >
                  <div className="section sigma_post-single">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="post-detail-wrapper">
                            <div className="entry-content">
                              <div className="sigma_post-meta">
                                <div className="sigma_post-categories">
                                  <a className="sigma_post-category">
                                    {t("medee")}
                                  </a>
                                </div>
                              </div>
                              <h4 className="entry-title">
                                {t("news_2024_02_8_04_title")}{" "}
                              </h4>

                              <a
                                className="gallery-thumb"
                              >
                                <img src="/surgalt4.jpeg" style={{width:"100%"}} height="auto"/>
                              </a>

                              <p>
                                {
                                  t("news_2024_02_8_04_text_1")
                                }
                              </p>

                              <p>
                                {
                                  t("news_2024_02_8_04_text_2")
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div
                  className={"tab-pane fade " + (id === 17 ? "show active" : "")}
                  id="news_2024_02_8_05"
                  role="tabpanel"
                  aria-labelledby="news_2024_02_8_05-tab"
                >
                  <div className="section sigma_post-single">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="post-detail-wrapper">
                            <div className="entry-content">
                              <div className="sigma_post-meta">
                                <div className="sigma_post-categories">
                                  <a className="sigma_post-category">
                                    {t("medee")}
                                  </a>
                                </div>
                              </div>
                              <h4 className="entry-title">
                                {t("news_2024_02_8_05_title")}{" "}
                              </h4>

                              <a
                                className="gallery-thumb"
                              >
                                <img src="/surgalt6.jpeg" style={{width:"100%"}} height="auto"/>
                              </a>

                              <p>
                                {
                                  t("news_2024_02_8_05_text_1")
                                }
                              </p>

                              <p>
                                {
                                  t("news_2024_02_8_05_text_2")
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={"tab-pane fade " + (id === 18 ? "show active" : "")}
                  id="nomiinneelt"
                  role="tabpanel"
                  aria-labelledby="nomiinneelt-tab"
                >
                  <div className="section sigma_post-single">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="post-detail-wrapper">
                            <div className="entry-content">
                              <div className="sigma_post-meta">
                                <div className="sigma_post-categories">
                                  <a className="sigma_post-category">
                                    {t("medee")}
                                  </a>
                                </div>
                              </div>
                              <h4 className="entry-title">
                                {t("news_title_nomiinneelt")}{" "}
                              </h4>

                              <a
                                className="gallery-thumb"
                              >
                                <img src="/nomiinneelt1.jpg" style={{width:"100%"}} height="auto"/>
                              </a>

                              <a
                                className="gallery-thumb"
                              >
                                <img src="/nomiinneelt2.jpg" style={{width:"100%"}} height="auto"/>
                              </a>

                              <p>
                                {
                                  t("news_1_nomiinneelt")
                                }
                              </p>

                              <p>
                                {
                                  t("news_2_nomiinneelt")
                                }
                              </p>

                              <h1>{t("news_3_nomiinneelt")}</h1>

                              <p>{t("news_4_nomiinneelt")}</p>
                              <p>{t("news_5_nomiinneelt")}</p>
                              <p>{t("news_6_nomiinneelt")}</p>
                              <p>{t("news_7_nomiinneelt")}</p>
                              <p>{t("news_8_nomiinneelt")}</p>
                              <p>{t("news_9_nomiinneelt")}</p>
                              <p>{t("news_10_nomiinneelt")}</p>
                              <hr />
                              <p>{t("news_11_nomiinneelt")}</p>
                              <p>{t("news_12_nomiinneelt")}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



              </div>

              <ul className="navs nav-tabs col-lg-4 " role="tablist">

              <li className="nav-item">
                  <a
                    className={"nav-link " + (id === 18 ? "active" : "")}
                    id="nomiinneelt-tab"
                    data-bs-toggle="tab"
                    href="#nomiinneelt"
                    role="tab"
                    aria-controls="nomiinneelt"
                    aria-selected={id === 18}
                  >
                    {" "}
                    <article
                      className="sigma_recent-post"
                      style={{
                        display: "flex",
                      }}
                    >
                      <a>
                        <img
                          src="nomiinneelt1.jpg"
                          alt="post"
                          style={{
                            marginTop: "10px",
                            width: "80px",
                            position: "relative",
                            height: "60px",
                          }}
                        />
                      </a>
                      <div className="sigma_recent-post-body">
                        <h6>
                          {" "}
                          <a>{t("news_title_nomiinneelt")} </a>{" "}
                        </h6>
                        <a>
                          {" "}
                          <i className="far fa-calendar"></i>{" "}
                          {t("news_day_nomiinneelt")}
                        </a>
                      </div>
                    </article>
                  </a>
                </li>

              <li className="nav-item">
                  <a
                    className={"nav-link " + (id === 17 ? "active" : "")}
                    id="news_2024_02_8_05-tab"
                    data-bs-toggle="tab"
                    href="#news_2024_02_8_05"
                    role="tab"
                    aria-controls="news_2024_02_8_05"
                    aria-selected={id === 17}
                  >
                    {" "}
                    <article
                      className="sigma_recent-post"
                      style={{
                        display: "flex",
                      }}
                    >
                      <a>
                        <img
                          src="viber_image_2024-01-10_15-38-31-009.jpg"
                          alt="post"
                          style={{
                            marginTop: "10px",
                            width: "80px",
                            position: "relative",
                            height: "60px",
                          }}
                        />
                      </a>
                      <div className="sigma_recent-post-body">
                        <h6>
                          {" "}
                          <a>{t("news_2024_02_8_05_title")} </a>{" "}
                        </h6>
                        <a>
                          {" "}
                          <i className="far fa-calendar"></i>{" "}
                          {t("news_2024_02_8_05_day")}
                        </a>
                      </div>
                    </article>
                  </a>
                </li>


              <li className="nav-item">
                  <a
                    className={"nav-link " + (id === 16 ? "active" : "")}
                    id="news_2024_02_8_04-tab"
                    data-bs-toggle="tab"
                    href="#news_2024_02_8_04"
                    role="tab"
                    aria-controls="news_2024_02_8_04"
                    aria-selected={id === 16}
                  >
                    {" "}
                    <article
                      className="sigma_recent-post"
                      style={{
                        display: "flex",
                      }}
                    >
                      <a>
                        <img
                          src="viber_image_2024-01-10_15-38-31-009.jpg"
                          alt="post"
                          style={{
                            marginTop: "10px",
                            width: "80px",
                            position: "relative",
                            height: "60px",
                          }}
                        />
                      </a>
                      <div className="sigma_recent-post-body">
                        <h6>
                          {" "}
                          <a>{t("news_2024_02_8_04_title")} </a>{" "}
                        </h6>
                        <a>
                          {" "}
                          <i className="far fa-calendar"></i>{" "}
                          {t("news_2024_02_8_04_day")}
                        </a>
                      </div>
                    </article>
                  </a>
                </li>




              <li className="nav-item">
                  <a
                    className={"nav-link " + (id === 15 ? "active" : "")}
                    id="news_2024_02_8_03-tab"
                    data-bs-toggle="tab"
                    href="#news_2024_02_8_03"
                    role="tab"
                    aria-controls="news_2024_02_8_03"
                    aria-selected={id === 15}
                  >
                    {" "}
                    <article
                      className="sigma_recent-post"
                      style={{
                        display: "flex",
                      }}
                    >
                      <a>
                        <img
                          src="viber_image_2024-01-10_15-38-31-009.jpg"
                          alt="post"
                          style={{
                            marginTop: "10px",
                            width: "80px",
                            position: "relative",
                            height: "60px",
                          }}
                        />
                      </a>
                      <div className="sigma_recent-post-body">
                        <h6>
                          {" "}
                          <a>{t("news_2024_02_8_03_title")} </a>{" "}
                        </h6>
                        <a>
                          {" "}
                          <i className="far fa-calendar"></i>{" "}
                          {t("news_2024_02_8_03_day")}
                        </a>
                      </div>
                    </article>
                  </a>
                </li>





              <li className="nav-item">
                  <a
                    className={"nav-link " + (id === 14 ? "active" : "")}
                    id="news_2024_02_8_02-tab"
                    data-bs-toggle="tab"
                    href="#news_2024_02_8_02"
                    role="tab"
                    aria-controls="news_2024_02_8_02"
                    aria-selected={id === 14}
                  >
                    {" "}
                    <article
                      className="sigma_recent-post"
                      style={{
                        display: "flex",
                      }}
                    >
                      <a>
                        <img
                          src="viber_image_2024-01-10_15-38-31-009.jpg"
                          alt="post"
                          style={{
                            marginTop: "10px",
                            width: "80px",
                            position: "relative",
                            height: "60px",
                          }}
                        />
                      </a>
                      <div className="sigma_recent-post-body">
                        <h6>
                          {" "}
                          <a>{t("news_2024_02_8_02_title")} </a>{" "}
                        </h6>
                        <a>
                          {" "}
                          <i className="far fa-calendar"></i>{" "}
                          {t("news_2024_02_8_02_day")}
                        </a>
                      </div>
                    </article>
                  </a>
                </li>





              <li className="nav-item">
                  <a
                    className={"nav-link " + (id === 12 ? "active" : "")}
                    id="news_2024_02_8_01-tab"
                    data-bs-toggle="tab"
                    href="#news_2024_02_8_01"
                    role="tab"
                    aria-controls="news_2024_02_8_01"
                    aria-selected={id === 12}
                  >
                    {" "}
                    <article
                      className="sigma_recent-post"
                      style={{
                        display: "flex",
                      }}
                    >
                      <a>
                        <img
                          src="viber_image_2024-01-10_15-38-31-009.jpg"
                          alt="post"
                          style={{
                            marginTop: "10px",
                            width: "80px",
                            position: "relative",
                            height: "60px",
                          }}
                        />
                      </a>
                      <div className="sigma_recent-post-body">
                        <h6>
                          {" "}
                          <a>{t("news_2024_02_8_01_title")} </a>{" "}
                        </h6>
                        <a>
                          {" "}
                          <i className="far fa-calendar"></i>{" "}
                          {t("news_2024_02_8_01_day")}
                        </a>
                      </div>
                    </article>
                  </a>
                </li>




              <li className="nav-item">
                  <a
                    className={"nav-link " + (id === 12 ? "active" : "")}
                    id="news_2024_02_7_01-tab"
                    data-bs-toggle="tab"
                    href="#news_2024_02_7_01"
                    role="tab"
                    aria-controls="news_2024_02_7_01"
                    aria-selected={id === 12}
                  >
                    {" "}
                    <article
                      className="sigma_recent-post"
                      style={{
                        display: "flex",
                      }}
                    >
                      <a>
                        <img
                          src="viber_image_2024-01-10_15-38-31-009.jpg"
                          alt="post"
                          style={{
                            marginTop: "10px",
                            width: "80px",
                            position: "relative",
                            height: "60px",
                          }}
                        />
                      </a>
                      <div className="sigma_recent-post-body">
                        <h6>
                          {" "}
                          <a>{t("news_2024_02_7_01_title")} </a>{" "}
                        </h6>
                        <a>
                          {" "}
                          <i className="far fa-calendar"></i>{" "}
                          {t("news_2024_02_7_01_day")}
                        </a>
                      </div>
                    </article>
                  </a>
                </li>





                <li className="nav-item">
                  <a
                    className={"nav-link " + (id === 1 ? "active" : "")}
                    id="surgalt_2024-tab"
                    data-bs-toggle="tab"
                    href="#surgalt_2024"
                    role="tab"
                    aria-controls="surgalt_2024"
                    aria-selected={id === 1}
                  >
                    {" "}
                    <article
                      className="sigma_recent-post"
                      style={{
                        display: "flex",
                      }}
                    >
                      <a>
                        <img
                          src="viber_image_2024-01-10_15-38-31-009.jpg"
                          alt="post"
                          style={{
                            marginTop: "10px",
                            width: "80px",
                            position: "relative",
                            height: "60px",
                          }}
                        />
                      </a>
                      <div className="sigma_recent-post-body">
                        <h6>
                          {" "}
                          <a>{t("surgalt_2024_title")} </a>{" "}
                        </h6>
                        <a>
                          {" "}
                          <i className="far fa-calendar"></i>{" "}
                          {t("surgalt_2024_day")}
                        </a>
                      </div>
                    </article>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={"nav-link " + (id === 11 ? "active" : "")}
                    id="ticket-tab"
                    data-bs-toggle="tab"
                    href="#ticket"
                    role="tab"
                    aria-controls="ticket"
                    aria-selected={id === 11}
                  >
                    {" "}
                    <article
                      className="sigma_recent-post"
                      style={{
                        display: "flex",
                      }}
                    >
                      <a>
                        <img
                          src="ticket.jpg"
                          alt="post"
                          style={{
                            marginTop: "10px",
                            width: "80px",
                            position: "relative",
                            height: "60px",
                          }}
                        />
                      </a>
                      <div className="sigma_recent-post-body">
                        <h6>
                          {" "}
                          <a>{t("ticket_title")} </a>{" "}
                        </h6>
                        <a>
                          {" "}
                          <i className="far fa-calendar"></i> {t("ticket_day")}
                        </a>
                      </div>
                    </article>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={"nav-link " + (id === 10 ? "active" : "")}
                    id="meeting-tab"
                    data-bs-toggle="tab"
                    href="#meeting"
                    role="tab"
                    aria-controls="meeting"
                    aria-selected={id === 10}
                  >
                    {" "}
                    <article
                      className="sigma_recent-post"
                      style={{
                        display: "flex",
                      }}
                    >
                      <a>
                        <img
                          src="meeting.jpg"
                          alt="post"
                          style={{
                            marginTop: "10px",
                            width: "80px",
                            position: "relative",
                            height: "60px",
                          }}
                        />
                      </a>
                      <div className="sigma_recent-post-body">
                        <h6>
                          {" "}
                          <a>{t("news_title_meeting")} </a>{" "}
                        </h6>
                        <a>
                          {" "}
                          <i className="far fa-calendar"></i>{" "}
                          {t("news_day_meeting")}
                        </a>
                      </div>
                    </article>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={"nav-link " + (id === 9 ? "active" : "")}
                    id="surgalt-tab"
                    data-bs-toggle="tab"
                    href="#surgalt"
                    role="tab"
                    aria-controls="surgalt"
                    aria-selected={id === 9}
                  >
                    {" "}
                    <article
                      className="sigma_recent-post"
                      style={{
                        display: "flex",
                      }}
                    >
                      <a>
                        <img
                          src="surgalt1.jpg"
                          alt="post"
                          style={{
                            marginTop: "10px",
                            width: "80px",
                            position: "relative",
                            height: "60px",
                          }}
                        />
                      </a>
                      <div className="sigma_recent-post-body">
                        <h6>
                          {" "}
                          <a>{t("news_title_surgalt")} </a>{" "}
                        </h6>
                        <a>
                          {" "}
                          <i className="far fa-calendar"></i>{" "}
                          {t("news_day_surgalt")}
                        </a>
                      </div>
                    </article>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={"nav-link " + (id === 8 ? "active" : "")}
                    id="jado-tab"
                    data-bs-toggle="tab"
                    href="#jado"
                    role="tab"
                    aria-controls="jado"
                    aria-selected={id === 8}
                  >
                    {" "}
                    <article
                      className="sigma_recent-post"
                      style={{
                        display: "flex",
                      }}
                    >
                      <a>
                        <img
                          src="jado1.jpg"
                          alt="post"
                          style={{
                            marginTop: "10px",
                            width: "80px",
                            position: "relative",
                            height: "60px",
                          }}
                        />
                      </a>
                      <div className="sigma_recent-post-body">
                        <h6>
                          {" "}
                          <a>{t("news_title_jado")} </a>{" "}
                        </h6>
                        <a>
                          {" "}
                          <i className="far fa-calendar"></i>{" "}
                          {t("news_day_jado")}
                        </a>
                      </div>
                    </article>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={"nav-link " + (id === 7 ? "active" : "")}
                    id="handmaa-tab"
                    data-bs-toggle="tab"
                    href="#handmaa"
                    role="tab"
                    aria-controls="handmaa"
                    aria-selected={id === 7}
                  >
                    {" "}
                    <article
                      className="sigma_recent-post"
                      style={{
                        display: "flex",
                      }}
                    >
                      <a>
                        <img
                          src="handmaa.jpg"
                          alt="post"
                          style={{
                            marginTop: "10px",
                            width: "80px",
                            position: "relative",
                            height: "60px",
                          }}
                        />
                      </a>
                      <div className="sigma_recent-post-body">
                        <h6>
                          {" "}
                          <a>{t("news_title_handmaa")} </a>{" "}
                        </h6>
                        <a>
                          {" "}
                          <i className="far fa-calendar"></i>{" "}
                          {t("news_day_handmaa")}
                        </a>
                      </div>
                    </article>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={"nav-link " + (id === 6 ? "active" : "")}
                    id="hatan-tab"
                    data-bs-toggle="tab"
                    href="#hatan"
                    role="tab"
                    aria-controls="hatan"
                    aria-selected={id === 6}
                  >
                    {" "}
                    <article
                      className="sigma_recent-post"
                      style={{
                        display: "flex",
                      }}
                    >
                      <a>
                        <img
                          src="hatan.jpg"
                          alt="post"
                          style={{
                            marginTop: "10px",
                            width: "80px",
                            position: "relative",
                            height: "60px",
                          }}
                        />
                      </a>
                      <div className="sigma_recent-post-body">
                        <h6>
                          {" "}
                          <a>{t("news_title_hatan")} </a>{" "}
                        </h6>
                        <a>
                          {" "}
                          <i className="far fa-calendar"></i>{" "}
                          {t("news_day_hatan")}
                        </a>
                      </div>
                    </article>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={"nav-link " + (id === 5 ? "active" : "")}
                    id="last-tab"
                    data-bs-toggle="tab"
                    href="#last"
                    role="tab"
                    aria-controls="last"
                    aria-selected={id === 5}
                  >
                    {" "}
                    <article
                      className="sigma_recent-post"
                      style={{
                        display: "flex",
                      }}
                    >
                      <a>
                        <img
                          src="medee4(1).jpg"
                          alt="post"
                          style={{ marginTop: "10px", width: "80px" }}
                        />
                      </a>
                      <div className="sigma_recent-post-body">
                        <h6>
                          {" "}
                          <a>{t("news_title_4")} </a>{" "}
                        </h6>
                        <a>
                          {" "}
                          <i className="far fa-calendar"></i> {t("news_4_day")}
                        </a>
                      </div>
                    </article>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={"nav-link " + (id === 3 ? "active" : "")}
                    id="contact-tab"
                    data-bs-toggle="tab"
                    href="#contact"
                    role="tab"
                    aria-controls="contact"
                    aria-selected={id === 3}
                  >
                    {" "}
                    <article
                      className="sigma_recent-post"
                      style={{
                        display: "flex",
                      }}
                    >
                      <a>
                        <img
                          src="news.jpg"
                          alt="post"
                          style={{ marginTop: "10px", width: "80px" }}
                        />
                      </a>
                      <div className="sigma_recent-post-body">
                        <h6>
                          {" "}
                          <a>{t("news_title")} </a>{" "}
                        </h6>
                        <a>
                          {" "}
                          <i className="far fa-calendar"></i> {t("news_ognoo")}
                        </a>
                      </div>
                    </article>
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className={"nav-link " + (id === 2 ? "active" : "")}
                    id="profile-tab"
                    data-bs-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected={id === 2}
                  >
                    {" "}
                    <article
                      className="sigma_recent-post"
                      style={{
                        display: "flex",
                      }}
                    >
                      <a>
                        <img
                          src="baishin.jpg"
                          alt="post"
                          style={{ marginTop: "10px", width: "80px" }}
                        />
                      </a>
                      <div className="sigma_recent-post-body">
                        <h6>
                          {" "}
                          <a>{t("sum_hiid_text")} </a>{" "}
                        </h6>
                        <a>
                          {" "}
                          <i className="far fa-calendar"></i>{" "}
                          {t("sum_hiid_day")}
                        </a>
                      </div>
                    </article>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={"nav-link " + (id === 4 ? "active" : "")}
                    id="home-tab"
                    data-bs-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected={id === 4}
                  >
                    {" "}
                    <article
                      className="sigma_recent-post"
                      style={{
                        display: "flex",
                      }}
                    >
                      <a>
                        <img
                          src="/odriin_bayr2.jpg"
                          alt="post"
                          style={{ marginTop: "10px", width: "80px" }}
                        />
                      </a>
                      <div className="sigma_recent-post-body">
                        <h6>
                          {" "}
                          <a>{t("buddha")} </a>{" "}
                        </h6>
                        <a>
                          {" "}
                          <i className="far fa-calendar"></i> {t("buddha_day")}
                        </a>
                      </div>
                    </article>{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default MedeeDPage;
