import useTranslate from "../hooks/useTranslate";
export default function FAQSection() {
    const { language, setLanguage, t } = useTranslate("mn");

    return (
        <section className="section">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 d-none d-lg-block">
                        <img src="assets/img/about3.jpg" className="w-100" alt="about" />
                    </div>
                    <div className="col-lg-6">
                        <div className="me-lg-30">
                            <div className="section-title section-title-2 text-start">
                                
                                <h4 className="title">{t("gishuun")}</h4>
                                <p> {t("gishuun_text")}
                                     </p>
                            </div>
                            <div className="accordion with-gap" id="generalFAQExample">
                                <div className="card">
                                    <div className="card-header" data-bs-toggle="collapse" role="button" data-bs-target="#generalOne" aria-expanded="true" aria-controls="generalOne">
                                        {t("erh_uureg")}
                                    </div>
                                    <div id="generalOne" className="collapse show" data-bs-parent="#generalFAQExample">
                                        <div className="card-body">
                                        {t("erh_uureg_text")}

                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" data-bs-toggle="collapse" role="button" data-bs-target="#generalTwo" aria-expanded="false" aria-controls="generalTwo">
                                    {t("erh_zaawar")}
                                    </div>
                                    <div id="generalTwo" className="collapse" data-bs-parent="#generalFAQExample">
                                        <div className="card-body">
                                        {t("erh_zaawar_text")}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}