/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import Switch from "react-switch";
import React, { useState } from "react";
import useTranslate from "../hooks/useTranslate";
import ReactFlagsSelect from "react-flags-select";
import { useLocation } from "react-router-dom";

export default function LanguageSwitch() {
  const { t, language, setLanguage } = useTranslate();

  const [selected, setSelected] = useState("");
  const location = useLocation();
  const onSelect = (code) => {
    code = code.toLowerCase();
    if (code === "us") {
      code = "en";
    }
    setLanguage(code.toLowerCase());
    try {
      // window.location.reload(false);
      document.querySelector(".sigma_aside-overlay").click();
      console.log(document.querySelector(".sigma_aside-overlay"));
      //   document.getElementsByClassName(".aside-trigger-right")[0].click();
      console.log(document.getElementsByClassName("aside-trigger-right "));
    } catch (err) {
      console.log(err);
    }
  };
  let lang = language;
  if (lang === "en") {
    lang = "US";
  }

  return (
    <React.Fragment>
      {/* <div
        onClick={() => setLanguage(language === "en" ? "mn" : "en")}
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 6,
          cursor: "pointer",
          alignItems: "center",
        }}
      >
        {language === "en"
          ? [<img src="/us-flag.png" style={{ width: 32 }} />, "English"]
          : [<img src="/mongolia.png" style={{ width: 32 }} />, "Монгол хэл"]}
      </div> */}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 8,
          cursor: "pointer",
        }}
      >
        <img
          onClick={() => setLanguage("en")}
          src="/us-flag.png"
          style={{ width: 36 }}
        />
        <img
          onClick={() => setLanguage("mn")}
          src="/mongolia.png"
          style={{ width: 36 }}
        />
      </div>

      {/* <ReactFlagsSelect
        selected={lang.toUpperCase()}
        onSelect={onSelect}
        countries={["MN", "US"]}
        showSelectedLabel={"asd"}
        customLabels={{
          US: { primary: "EN" },
          MN: { primary: "MN" },
          // GB: { primary: "EN-GB", secondary: "+44" },
          // FR: "FR",
        }}
      /> */}
      {/* {language} */}
    </React.Fragment>
  );
}
