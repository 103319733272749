import useTranslate from "../../hooks/useTranslate";
import tosol from "./tosol.module.css";
import useQuery from "../../hooks/useQuery";
import React from "react";
const TosolPage = () => {
  const { language, setLanguage, t } = useTranslate("mn");
  const query = useQuery();
  const [id, setId] = React.useState(
    isNaN(parseInt(query.get("id"))) ? 1 : parseInt(query.get("id"))
  );
  console.log(id);
  const [selectImage, setSelectImage] = React.useState();

  const onClickImage = (id) => {
    setSelectImage(id);
  };
  return (
    <div>
      <div
        className="sigma_subheader dark-overlay dark-overlay-2"
        style={{
          backgroundImage:
            "url(https://templatebae.net/themes/html/stupa/assets/img/subheader.jpg)",
          height: "60vh",
          paddingTop: "25vh",
        }}
        //style="background-image: "
      >
        <div className="container">
          <div className="sigma_subheader-inner">
            <div className="sigma_subheader-text">
              <h1>{t("tosol")}</h1>
            </div>
          </div>
        </div>
      </div>

      <section className="section">
        <div className="container">
          <div className="row ">
            <div
              className="medeeD"
              style={{
                display: "flex",
              }}
            >
              <div className="tab-content " id="myTabContent">
                <div
                  className={"tab-pane fade "+(id === 1 ? "show active" : "")}
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="section sigma_post-single">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="post-detail-wrapper">
                            <div className="entry-content">
                              <div className="sigma_post-meta">
                                <div className="sigma_post-categories">
                                  <a className="sigma_post-category">
                                    {t("tosol_title")}
                                  </a>
                                </div>
                              </div>
                              <h4 className="entry-title">
                                {t("buddiin_title")}{" "}
                              </h4>
                              <div className="sigma_post-meta">
                                <a href="#"> {t("buddiin_day")}</a>
                              </div>
                              <p>{t("buddiin_text")}</p>

                              <a className="gallery-thumb">
                                {images[0].map((e, index) => {
                                  return (
                                    <img
                                      src={e}
                                      alt=""
                                      onClick={() => onClickImage(e)}
                                    />
                                  );
                                })}
                              </a>

                              <hr />

                              <p>{t("buddiin_text1")}</p>

                              <p>{t("buddiin_text2")}</p>

                              <p>{t("buddiin_text3")}</p>
                              <p>{t("buddiin_text4")}</p>
                            </div>

                            <div className="section"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={"tab-pane fade"+(id===3?"show active":"")}
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div className="section sigma_post-single">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="post-detail-wrapper">
                            <div className="entry-content">
                              <div className="sigma_post-meta">
                                <div className="sigma_post-categories">
                                  <a className="sigma_post-category">
                                    {t("tosol_title")}
                                  </a>
                                </div>
                              </div>
                              <h4 className="entry-title">{t("unshlag")} </h4>
                              <div className="sigma_post-meta">
                                <a href="#"> {t("unshlag_day")}</a>
                              </div>
                              <p>{t("unshlag_text1")}</p>

                              <a className="gallery-thumb">
                                {images[1].map((e, index) => {
                                  return (
                                    <img
                                      src={e}
                                      alt=""
                                      onClick={() => onClickImage(e)}
                                    />
                                  );
                                })}
                              </a>
                              {t("unshlag_text2")}
                            </div>

                            <div className="section"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={"tab-pane fade"+(id === 2 ? "show active" : "")}
                  id="contact"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                >
                  <div className="section sigma_post-single">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="post-detail-wrapper">
                            <div className="entry-content">
                              <div className="sigma_post-meta">
                                <div className="sigma_post-categories">
                                  <a className="sigma_post-category">
                                    {t("tosol_title")}
                                  </a>
                                </div>
                              </div>
                              <h4 className="entry-title">
                                {t("tosol")} 2023/06/03{" "}
                              </h4>
                              <div className="sigma_post-meta">
                                <a href="#">
                                  {" "}
                                  <i className="far fa-calendar"></i>
                                  {t("tosol_day")}
                                </a>
                              </div>
                              <p>{t("tosol_text")}</p>

                              <a className="gallery-thumb">
                                {images[2].map((e, index) => {
                                  return (
                                    <img
                                      src={e}
                                      alt=""
                                      onClick={() => onClickImage(e)}
                                    />
                                  );
                                })}
                              </a>

                              <p>{t("tosol_text1")}</p>
                              <hr />

                              <p>{t("tosol_text2")}</p>
                            </div>

                            <div className="section"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ul className="navs nav-tabs col-lg-4 " role="tablist">
                <li className="nav-item">
                  <a
                    className={"nav-link "+ (id === 1 ? "active" : "" )}
                    id="home-tab"
                    data-bs-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected={id === 1}
                  >
                    {" "}
                    <article
                      className="sigma_recent-post"
                      style={{
                        display: "flex",
                      }}
                    >
                      <a>
                        <img
                          src="assets/img/buddha_images/IMG_6885.JPG"
                          alt="post"
                          style={{ marginTop: "10px", width: "80px" }}
                        />
                      </a>
                      <div className="sigma_recent-post-body">
                        <h6>
                          {" "}
                          <a>{t("buddiin_title")} </a>{" "}
                        </h6>
                        <a>
                          {" "}
                          <i className="far fa-calendar"></i>
                          {t("buddiin_day")}
                        </a>
                      </div>
                    </article>{" "}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={"nav-link " + (id === 3 ? "active" : "" )}
                    id="profile-tab"
                    data-bs-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected={id===3}
                  >
                    {" "}
                    <article
                      className="sigma_recent-post"
                      style={{
                        display: "flex",
                      }}
                    >
                      <a>
                        <img
                          src="women.jpg "
                          alt="post"
                          style={{ height: "40px", width: "80px" }}
                        />
                      </a>
                      <div className="sigma_recent-post-body">
                        <h6>
                          {" "}
                          <a>{t("unshlag")} </a>{" "}
                        </h6>
                        <a>
                          {" "}
                          <i className="far fa-calendar"></i> {t("unshlag_day")}
                        </a>
                      </div>
                    </article>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={"nav-link " + (id === 2 ? "active" : "" )}
                    id="contact-tab"
                    data-bs-toggle="tab"
                    href="#contact"
                    role="tab"
                    aria-controls="contact"
                    aria-selected={id===2}
                  >
                    {" "}
                    <article
                      className="sigma_recent-post"
                      style={{
                        display: "flex",
                      }}
                    >
                      <a>
                        <img
                          src="tosol_back.jpg"
                          alt="post"
                          style={{ height: "50px", width: "80px" }}
                        />
                      </a>
                      <div className="sigma_recent-post-body">
                        <h6>
                          {" "}
                          <a>{t("tosol")} 2023/06/03 </a>{" "}
                        </h6>
                        <a>
                          {" "}
                          <i className="far fa-calendar"></i> {t("tosol_day")}
                        </a>
                      </div>
                    </article>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {selectImage && (
        <div
          className={"imgs position-fixed"}
          onClick={() => onClickImage(null)}
          style={{ visibility: "visible" }}
        >
          <img
            style={{
              height: "80vh",
              width: "auto",
            }}
            src={selectImage}
            alt=""
          />
        </div>
      )}
    </div>
  );
};
export default TosolPage;
const images = [
  ["assets/img/buddha_images/IMG_6885.JPG"],
  ["women.jpg"],
  ["tosol_back.jpg"],
];
