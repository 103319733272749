const HolbootuhaiPage = ()=>{
    return(
        <div>
             <div className="sigma_subheader dark-overlay dark-overlay-2"
             style={
                {
                    backgroundImage: "url(https://templatebae.net/themes/html/stupa/assets/img/subheader.jpg)"
                }
             }
             // style="background-image: "
             >

<div className="container">
  <div className="sigma_subheader-inner">
    <div className="sigma_subheader-text">
      <h1>Community Details</h1>
    </div>
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><a className="btn-link" href="#">Home</a></li>
        <li className="breadcrumb-item active" aria-current="page">Community Details</li>
      </ol>
    </nav>
  </div>
</div>

</div>


<div className="section">
<div className="container">

  <div className="entry-content">
    <span className="fw-600 custom-primary text-uppercase">Temple Service</span>
    <h3 className="entry-title">Stupa Family Temple Service</h3>

    <div className="sigma_post-single-thumb">
      <img src="https://templatebae.net/themes/html/stupa/assets/img/community/details/1.jpg" alt="video"/>
      <div className="sigma_box">
        <div className="sigma_list-item">
          <label>Client:</label>
          <span>Stupa Family Temple Service</span>
        </div>
        <div className="sigma_list-item">
          <label>Date:</label>
          <span>29 December 2022</span>
        </div>
        <div className="sigma_list-item">
          <label>Category:</label>
          <span>Community</span>
        </div>
        <div className="sigma_list-item">
          <ul className="sigma_sm">
            <li>
              <a href="#">
                <i className="fab fa-facebook-f"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="fab fa-youtube"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <p>
      Buddhism is a way to wisdom, peace, harmony of mind and soul, an exploration of the life in all its forms. Powerful breathing techniques and wisdom that will transform your life. Equip yourself with practical knowledge
      specimen book.
      It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more
      recently with
      techniques to unlock your true potential and bring fullness to your life.
    </p>
    <div className="section">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="sigma_canvas">
            <canvas id="myChart"></canvas>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="me-lg-30">
            <div className="section-title mb-0 text-start">
              <p className="subtitle">Benefits</p>
              <h4 className="title">Best Market Around</h4>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. <a href="#">Lorem Ipsum has</a> been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type
              specimen book.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p>
      Buddhism is a way to wisdom, peace, harmony of mind and soul, an exploration of the life in all its forms. Powerful breathing techniques and wisdom that will transform your life. Equip yourself with practical knowledge
      specimen book.
      It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more
      recently with
      techniques to unlock your true potential and bring fullness to your life.
    </p>
    <div className="row">
      <div className="col-md-6">
        <a href="https://templatebae.net/themes/html/stupa/assets/img/community/details/2.jpg" className="gallery-thumb">
          <img src="https://templatebae.net/themes/html/stupa/assets/img/community/details/2.jpg" alt="post"/>
        </a>
      </div>
      <div className="col-md-6">
        <a href="https://templatebae.net/themes/html/stupa/assets/img/community/details/3.jpg" className="gallery-thumb">
          <img src="https://templatebae.net/themes/html/stupa/assets/img/community/details/3.jpg" alt="post"/>
        </a>
      </div>
    </div>
    <p>
      Several monks reside at Great Lotus. Their exact number depends on the current events or activities: Buddhist rites and Thai culture ceremonies. Occasionally, monks are invited to do the blessing
      galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release
      of Letraset sheets
      containing Lorem Ipsum passages, and more recently with techniques to unlock your true potential and bring fullness to your life..
    </p>
    <p>
      Several monks reside at Great Lotus. Their exact number depends on the current events or activities: Buddhist rites and Thai culture ceremonies. Occasionally, monks are invited to do the blessing
      galley of type and scrambled it to make a <a href="#">type specimen book.</a> It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release
      of Letraset sheets
      containing Lorem Ipsum passages, and more recently with techniques to unlock your true potential and bring fullness to your life..
      Lorem Ipsum is simply dummy <a href="#">text of the printing and typesetting industry.</a> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
      galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release
      of Letraset sheets
      containing Lorem Ipsum passages, and more recently with techniques to unlock your true potential and bring fullness to your life..
    </p>
    <div className="row">
      <div className="col-md-4">
        <a href="https://templatebae.net/themes/html/stupa/assets/img/community/details/4.jpg" className="gallery-thumb">
          <img src="https://templatebae.net/themes/html/stupa/assets/img/community/details/4.jpg" alt="post"/>
        </a>
      </div>
      <div className="col-md-4">
        <a href="https://templatebae.net/themes/html/stupa/assets/img/community/details/5.jpg" className="gallery-thumb">
          <img src="https://templatebae.net/themes/html/stupa/assets/img/community/details/5.jpg" alt="post"/>
        </a>
      </div>
      <div className="col-md-4">
        <a href="https://templatebae.net/themes/html/stupa/assets/img/community/details/6.jpg" className="gallery-thumb">
          <img src="https://templatebae.net/themes/html/stupa/assets/img/community/details/6.jpg" alt="post"/>
        </a>
        </div>
    
    

  </div>

</div>
</div>
</div>
</div>
    )
}
export default HolbootuhaiPage;