import useTranslate from "../../hooks/useTranslate";
const GishuudPage = () => {
  const { language, setLanguage, t } = useTranslate("mn");
    return(
        <div>

  <div className="sigma_subheader dark-overlay dark-overlay-2" 
  style={
    {
        backgroundImage: " url(https://templatebae.net/themes/html/stupa/assets/img/subheader.jpg)",
        height: "60vh",
        paddingTop: "25vh"
    }
  }
  //style="background-image: url(https://templatebae.net/themes/html/stupa/assets/img/subheader.jpg)"
  >

    <div className="container">
      <div className="sigma_subheader-inner">
        <div className="sigma_subheader-text">
          <h1>{t("gishuuds")}</h1>
        </div>
        
      </div>
    </div>

  </div>



  <div className="section section-padding">
    <div className="container">

      <div className="row">

        <div className="col-lg-3.col-md-6 gishuudiin_tuhai">
          <div className="sigma_volunteers volunteers-4">
            <div className="sigma_volunteers-thumb">
              <img src="assets/img/buddha_images/IMG-3171.JPG" alt="volunteers" />
              <ul className="sigma_sm">
                <li> <a href="/terguun" className="trigger-volunteers-socials"> <i className="fal fa-plus"></i> </a> </li>
                <li> <a href="/terguun"> <i className="fab fa-facebook-f"></i> </a> </li>
                <li> <a href="/terguun"> <i className="fab fa-twitter"></i> </a> </li>
                <li> <a href="/terguun"> <i className="fab fa-instagram"></i> </a> </li>
              </ul>
            </div>
            <div className="sigma_volunteers-body">
              <div className="sigma_volunteers-info">
                <p>{t("terguun_Lname")}</p>
                <h5>
                  <a href="/terguun">{t("terguun_Fname")}</a>
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3.col-md-6 gishuudiin_tuhai">
          <div className="sigma_volunteers volunteers-4">
            <div className="sigma_volunteers-thumb">
              <img src="2gishuun.jpg" alt="volunteers" />
              <ul className="sigma_sm">
                <li> <a href="/sarangua" className="trigger-volunteers-socials"> <i className="fal fa-plus"></i> </a> </li>
                <li> <a href="/sarangua"> <i className="fab fa-facebook-f"></i> </a> </li>
                <li> <a href="/sarangua"> <i className="fab fa-twitter"></i> </a> </li>
                <li> <a href="/sarangua"> <i className="fab fa-instagram"></i> </a> </li>
              </ul>
            </div>
            <div className="sigma_volunteers-body">
              <div className="sigma_volunteers-info">
                <p>{t("O_saran")}</p>
                <h5>
                  <a href="/sarangua">{t("saran")} </a>
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3.col-md-6 gishuudiin_tuhai">
          <div className="sigma_volunteers volunteers-4">
            <div className="sigma_volunteers-thumb">
              <img src="gishuun3.jpg" alt="volunteers" />
              <ul className="sigma_sm">
                <li> <a href="/gishuun3" className="trigger-volunteers-socials"> <i className="fal fa-plus"></i> </a> </li>
                <li> <a href="/gishuun3"> <i className="fab fa-facebook-f"></i> </a> </li>
                <li> <a href="/gishuun3"> <i className="fab fa-twitter"></i> </a> </li>
                <li> <a href="/gishuun3"> <i className="fab fa-instagram"></i> </a> </li>
              </ul>
            </div>
            <div className="sigma_volunteers-body">
              <div className="sigma_volunteers-info">
                <p>{t("Sa_aruin")}</p>
                <h5>
                  <a href="/gishuun3">{t("aruin")}</a>
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3.col-md-6 gishuudiin_tuhai">
          <div className="sigma_volunteers volunteers-4">
            <div className="sigma_volunteers-thumb">
              <img src="gishuun4.jpg" alt="volunteers" />
              <ul className="sigma_sm">
                <li> <a href="/gishuun4" className="trigger-volunteers-socials"> <i className="fal fa-plus"></i> </a> </li>
                <li> <a href="/gishuun4"> <i className="fab fa-facebook-f"></i> </a> </li>
                <li> <a href="/gishuun4"> <i className="fab fa-twitter"></i> </a> </li>
                <li> <a href="/gishuun4"> <i className="fab fa-instagram"></i> </a> </li>
              </ul>
            </div>
            <div className="sigma_volunteers-body">
              <div className="sigma_volunteers-info">
                <p>{t("g_javz")}</p>
                <h5>
                  <a href="/gishuun4">{t("javz")}</a>
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3.col-md-6 gishuudiin_tuhai">
          <div className="sigma_volunteers volunteers-4">
            <div className="sigma_volunteers-thumb">
              <img src="gishuun5.jpg" alt="volunteers" />
              <ul className="sigma_sm">
                <li> <a href="/gishuun5" className="trigger-volunteers-socials"> <i className="fal fa-plus"></i> </a> </li>
                <li> <a href="/gishuun5"> <i className="fab fa-facebook-f"></i> </a> </li>
                <li> <a href="/gishuun5"> <i className="fab fa-twitter"></i> </a> </li>
                <li> <a href="/gishuun5"> <i className="fab fa-instagram"></i> </a> </li>
              </ul>
            </div>
            <div className="sigma_volunteers-body">
              <div className="sigma_volunteers-info">
                <p>{t("d_mar")}</p>
                <h5>
                  <a href="/gishuun5">{t("mar")}</a>
                </h5>
              </div>
            </div>
          </div>
        </div>
        











      </div>

    </div>
  </div>
        </div>
    );
};
export default GishuudPage;