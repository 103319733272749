import useTranslate from "../../hooks/useTranslate";
import styles from "./Banner.module.css";


export default function Banner() {
  const { t } = useTranslate();

  return (
    <div className="sigma_banner banner-2">
      <div className="sigma_banner-slider">
        <div
          className={`light-bg sigma_banner-slider-inner bg-cover bg-center dark-overlay dark-overlay-2 bg-norepeat ${styles.banner}`}
        >
          <div className="sigma_banner-text">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 text-center">
                  {/* <span className="highlight-text">We Believe in Buddha</span> */}
                  <h1 className="text-white title" style={{whiteSpace: "nowrap"}}>{t("home_title")}</h1>
                  <p className="mb-0">
                    {t("home_subtitle")}
                  </p>
                  <a href="/about" className="sigma_btn-custom section-button">
                    {t("home_button")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="light-bg sigma_banner-slider-inner bg-cover bg-center dark-overlay dark-overlay-2 bg-norepeat" style={{ backgroundImage: 'url("assets/img/banner/5.jpg")' }}>
                    <div className="sigma_banner-text">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-6 text-center">
                                    <span className="highlight-text">We Believe in Buddha</span>
                                    <h1 className="text-white title">Temple Of Buddha</h1>
                                    <p className="mb-0"> Stupa is near from your family. If you trust them! Vestibulum ac diam sit amet quam </p>
                                    <a href="about-us.html" className="sigma_btn-custom section-button">Explore Meditation <i className="far fa-arrow-right" /> </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
      </div>
    </div>
  );
}
