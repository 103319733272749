import useTranslate from "../../hooks/useTranslate";
import React from "react";
const HandiwtuhaiPage = () => {
  const { language, setLanguage, t } = useTranslate("mn");

  return (
    <div>
      <div
        className="sigma_subheader dark-overlay dark-overlay-2"
        style={{
          backgroundImage:
            "url(https://templatebae.net/themes/html/stupa/assets/img/subheader.jpg)",
          height: "60vh",
          paddingTop: "25vh",
        }}
        //style="background-image: "
      >
        <div className="container">
          <div className="sigma_subheader-inner">
            <div className="sigma_subheader-text">
              <h1> {t("handiw")} </h1>
            </div>
            
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <div className="row sigma_broadcast-video">
            <div className="col-12 mb-5">
              <div className="row g-0 align-items-center">
                <div className="col-lg-6">
                  <div className="sigma_video-popup-wrap">
                    <img
                      src="/baishin3.jpg"
                      alt="hi img"
                      style={{ marginBottom: "50px" }}
                    />
                    <img
                      src="/baishin1.jpg"
                      alt="hi img"
                      style={{ marginBottom: "50px" }}
                    />
                    <img src="/baishin2.jpg" alt="hi img" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="sigma_box m-0">
                    <h4 className="title">{t("sum_handiw")}</h4>
                    <p className="m-0"></p>
                    <div className="accordion with-gap" id="generalFAQExample">
                      <div className="card">
                        <div
                          id="generalOne"
                          className="collapse show"
                          data-bs-parent="#generalFAQExample"
                        >
                          <div className="card-body">
                            <p
                              style={{
                                color: "gray",
                              }}
                            >
                              <p>
                                {t("sum_handiw_text1")} <br />
                              </p>
                              <p>
                                {t("sum_handiw_text2")} <br />
                              </p>
                              <p>
                                {t("sum_handiw_text3")} <br />
                              </p>
                              <p>
                                {t("sum_handiw_text4")} <br />
                              </p>
                              <p>
                                {t("sum_handiw_text5")} <br />
                              </p>
                              <p>
                                {t("sum_handiw_text6")} <br />
                              </p>
                              <p>
                                {t("sum_handiw_text7")} <br />
                              </p>
                              <p>
                                {t("sum_handiw_text8")} <br />
                              </p>
                              <p>
                                {t("sum_handiw_text9")} <br />
                              </p>
                              <p>
                                {t("sum_handiw_text10")} <br />
                              </p>
                            </p>
                            <a href="/golomt" className="sigma_btn-custom">
                              {t("orgol1")}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HandiwtuhaiPage;
