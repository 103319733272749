import useTranslate from "../../hooks/useTranslate";

const BagshPage = () => {
  const { language, setLanguage, t } = useTranslate("mn");
  return (
    <div>
      <div
        className="sigma_subheader dark-overlay dark-overlay-2"
        style={{
          backgroundImage:
            " url(https://templatebae.net/themes/html/stupa/assets/img/subheader.jpg)",
          height: "60vh",
          paddingTop: "25vh",
        }}
        //style="background-image: url(https://templatebae.net/themes/html/stupa/assets/img/subheader.jpg)"
      >
        <div className="container">
          <div className="sigma_subheader-inner">
            <div className="sigma_subheader-text">
              <h1>{t("bagsh_title")}</h1>
            </div>
            
          </div>
        </div>
      </div>

      <div className="section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="sigma_volunteers volunteers-4">
                <div className="sigma_volunteers-thumb">
                  <img src="javzan_damb.jpg_large" alt="volunteers" />
                  <ul className="sigma_sm">
                    <li>
                      {" "}
                      <a href="/javzan" className="trigger-volunteers-socials">
                        {" "}
                        <i className="fal fa-plus"></i>{" "}
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="/javzan">
                        {" "}
                        <i className="fab fa-facebook-f"></i>{" "}
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="/javzan">
                        {" "}
                        <i className="fab fa-twitter"></i>{" "}
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="/javzan">
                        {" "}
                        <i className="fab fa-instagram"></i>{" "}
                      </a>{" "}
                    </li>
                  </ul>
                </div>
                <div className="sigma_volunteers-body">
                  <div className="sigma_volunteers-info">
                    <p>{t("javz_Lname")} </p>
                    <h5>
                      <a href="/javzan">{t("javz_Fname")}</a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="sigma_volunteers volunteers-4">
                <div className="sigma_volunteers-thumb">
                  <img
                    src="assets/img/buddha_images/IMG-3171.JPG"
                    alt="volunteers"
                  />
                  <ul className="sigma_sm">
                    <li>
                      {" "}
                      <a
                        href="/gishuudtuhai"
                        className="trigger-volunteers-socials"
                      >
                        {" "}
                        <i className="fal fa-plus"></i>{" "}
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="/gishuudtuhai">
                        {" "}
                        <i className="fab fa-facebook-f"></i>{" "}
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="/gishuudtuhai">
                        {" "}
                        <i className="fab fa-twitter"></i>{" "}
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="/gishuudtuhai">
                        {" "}
                        <i className="fab fa-instagram"></i>{" "}
                      </a>{" "}
                    </li>
                  </ul>
                </div>
                <div className="sigma_volunteers-body">
                  <div className="sigma_volunteers-info">
                    <p>{t("terguun_Lname")}</p>
                    <h5>
                      <a href="/gishuudtuhai">{t("terguun_Fname")}</a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BagshPage;
