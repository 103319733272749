import useTranslate from "../../hooks/useTranslate";
import styles from "./about.module.css";
const AboutPage = () => {
  const { language, setLanguage, t } = useTranslate("mn");
  return (
    <div>
      <div
        className={`sigma_subheader dark-overlay dark-overlay-2${styles.banner}`}
        //   style="background-image: url(https://templatebae.net/themes/html/stupa/assets/img/subheader.jpg)"
        style={{
          backgroundImage:
            " url(https://templatebae.net/themes/html/stupa/assets/img/subheader.jpg)",
          height: "60vh",
          paddingTop: "25vh",
        }}
      >
        <div className="container">
          <div className="sigma_subheader-inner">
            <div className="sigma_subheader-text">
              <h1>{t("bidnii")}</h1>
            </div>
          </div>
        </div>
      </div>
      <section className="section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 d-none d-lg-block">
              <div className="sigma_img-box">
                <div className="row">
                  <div className="col-lg-6">
                    <img
                      src="assets/img/buddha_images/IMG_3435.jpg"
                      alt="service"
                    />
                    <img
                      className="ms-0"
                      src="assets/img/buddha_images/IMG-9671.JPG"
                      alt="service"
                    />
                  </div>
                  <div className="col-lg-6 mt-0 mt-lg-5">
                    <img
                      src="assets/img/buddha_images/emegtei.jpg"
                      alt="service"
                    />
                    <img
                      className="ms-0"
                      src="assets/img/buddha_images/IMG_6899.JPG"
                      alt="service"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="me-lg-30">
                <div className="section-title mb-0 text-start">
                  <p className="subtitle">{t("bidnii_title")}</p>
                  <h4 className="title"></h4>
                </div>
                <p className="blockquote bg-transparent">
                  {" "}
                  {t("home_subtitle")}{" "}
                </p>
                <div className="sigma_icon-block icon-block-3">
                  <div className="icon-wrapper">
                    <img
                      src="https://templatebae.net/themes/html/stupa/assets/img/textures/icons/1.png"
                      alt=""
                    />
                  </div>
                  <div className="sigma_icon-block-content">
                    <h5> {t("mission")} </h5>
                    <p>{t("mission_text")}</p>
                  </div>
                </div>
                <div className="sigma_icon-block icon-block-3">
                  <div className="icon-wrapper">
                    <img
                      src="https://templatebae.net/themes/html/stupa/assets/img/textures/icons/2.png"
                      alt=""
                    />
                  </div>
                  <div className="sigma_icon-block-content">
                    <h5> {t("zorilt")} </h5>
                    <p>{t("zorilt_text")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="section light-bg">
        <div className="container">
          <div className="sigma_timeline">
            <div className="row g-0 justify-content-end justify-content-md-around align-items-start sigma_timeline-nodes">
              <div className="col-12 col-md-5 order-3 order-md-1 sigma_timeline-content">
                <h4>{t("ow_ulamj_title")}</h4>
                <p>{t("ow_ulamj_text")} </p>
              </div>
              <div className="col-2 col-sm-1 px-md-3 order-2 sigma_timeline-image text-md-center">
                <i className="far fa-circle"></i>
              </div>
              <div className="col-12 col-md-5 order-1 order-md-3 pb-3 sigma_timeline-date">
                <span>2014</span>
                <br />
                <span className="sigma_timeline-month"></span>
              </div>
            </div>
            <div className="row g-0 justify-content-end justify-content-md-around align-items-start sigma_timeline-nodes">
              <div className="col-12 col-md-5 order-3 order-md-1 sigma_timeline-content">
                <h4>{t("neg_title")}</h4>
                <p>{t("neg_text")}</p>
              </div>
              <div className="col-2 col-sm-1 px-md-3 order-2 sigma_timeline-image text-md-center">
                <i className="far fa-circle"></i>
              </div>
              <div className="col-12 col-md-5 order-1 order-md-3 pb-3 sigma_timeline-date">
                <span></span>
                <br />
                <span className="sigma_timeline-month"></span>
              </div>
            </div>
            <div className="row g-0 justify-content-end justify-content-md-around align-items-start sigma_timeline-nodes">
              <div className="col-12 col-md-5 order-3 order-md-1 sigma_timeline-content">
                <h4>{t("hoyr_tilte")}</h4>
                <p>{t("hoyr_text")}</p>
              </div>
              <div className="col-2 col-sm-1 px-md-3 order-2 sigma_timeline-image text-md-center">
                <i className="far fa-circle"></i>
              </div>
              <div className="col-12 col-md-5 order-1 order-md-3 pb-3 sigma_timeline-date">
                <span></span>
                <br />
                <span className="sigma_timeline-month"></span>
              </div>
            </div>
            <div className="row g-0 justify-content-end justify-content-md-around align-items-start sigma_timeline-nodes">
              <div className="col-12 col-md-5 order-3 order-md-1 sigma_timeline-content">
                <h4>{t("guraw_title")}</h4>
                <p>{t("guraw_text")}</p>
              </div>

              <div className="col-2 col-sm-1 px-md-3 order-2 sigma_timeline-image text-md-center">
                <i className="far fa-circle"></i>
              </div>
              <div className="col-12 col-md-5 order-1 order-md-3 pb-3 sigma_timeline-date">
                <span></span>
                <br />
                <span className="sigma_timeline-month"></span>
              </div>
            </div>
            <div className="row g-0 justify-content-end justify-content-md-around align-items-start sigma_timeline-nodes">
              <div className="col-12 col-md-5 order-3 order-md-1 sigma_timeline-content">
                <h4>{t("dorow_title")} </h4>
                <p>{t("dorow_text")}</p>
              </div>
              <div className="col-12 col-md-5 order-1 order-md-3 pb-3 sigma_timeline-date">
                <span>2021</span>
                <br />
                <span className="sigma_timeline-month"></span>
              </div>

              <div className="col-2 col-sm-1 px-md-3 order-2 sigma_timeline-image text-md-center">
                <i className="far fa-circle"></i>
              </div>
              <div className="col-12 col-md-5 order-1 order-md-3 pb-3 sigma_timeline-date">
                <span></span>
                <br />
                <span className="sigma_timeline-month"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="me-lg-30">
                <div className="section-title mb-0 text-start">
                  <p className="subtitle">{t("terguun_title")}</p>
                  <h4 className="title">{t("terguun_name")}</h4>
                </div>
                <p className="blockquote bg-transparent">
                  {" "}
                  {t("terguun_text")}{" "}
                </p>

                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className=" nav-item ">
                    <a
                      className="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      {" "}
                      <i className="far fa-dharmachakra"></i>{" "}
                      {t("surgalt_title")}{" "}
                    </a>
                  </li>
                  <li className=" nav-item ">
                    <a
                      className="nav-link  "
                      id="profile-tab"
                      data-bs-toggle="tab"
                      href="#profile"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      {" "}
                      <i className=""></i> {t("erdem")}
                    </a>
                  </li>
                  <li className=" nav-item ">
                    <a
                      className="nav-link  "
                      id="contact-tab"
                      data-bs-toggle="tab"
                      href="#contact"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      {" "}
                      <i className=""></i>
                      {t("buyn")}
                    </a>
                  </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <p>
                      • ДАРЬ ЭХ БУРХАН хөтөлбөр
                      <br />
                      • ЖОД ЛУЙЖИН
                      <br />
                      • АЛТАН ГЭРЭЛ СУДАР УНШИХ
                      <br />
                      • ДАРЬ ЭХ БУРХАНЫ 10 ӨДРИЙН СУРГАЛТ
                      <br />
                      • ДАРЬ ЭХ БУРХАНЫ 21 ӨДРИЙН СУРГАЛТ
                      <br />
                      • ТӨВД ХЭЛНИЙ СУРГАЛТ
                      <br />
                    </p>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <p>
                      • Гэтэлгэгч Дарь Эх
                      <br />
                      • Лүйжин
                      <br />• Буддын эмэгтэйчүүдийн өв уламжлал
                    </p>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="contact"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <p></p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <img src="/women.jpg" alt="about" />
            </div>
          </div>
        </div>
      </section>

      <div className="section section-padding pt-0">
        <div className="container">
          <div className="section-title section-title-2 text-center">
            <h4 className="title">{t("tosol")}</h4>
          </div>

          {/* Article Start */}

          <div className="row">
            {/* Article Start */}
            <div className="col-lg-4 col-md-6">
              <article className="sigma_post style-2">
                <div className="sigma_post-thumb">
                  <a state={{ id: 1 }} href="/tosol?id=1">
                    <img
                      src="assets/img/buddha_images/IMG_6885.JPG"
                      alt="post"
                    />
                  </a>
                </div>
                <div className="sigma_post-body">
                  <div className="sigma_post-meta">
                    <a
                      state={{ id: 1 }}
                      href="/tosol?id=1"
                      className="sigma_post-date"
                    >
                      {" "}
                      <i className="far fa-calendar" /> {t("day")}
                    </a>
                  </div>
                  <h5>
                    {" "}
                    <a state={{ id: 1 }} href="/tosol?id=1">
                      {t("ow_ulamj_title")}
                    </a>{" "}
                  </h5>
                </div>
              </article>
            </div>
            {/* Article End */}
            {/* Article Start */}
            <div className="col-lg-4 col-md-6">
              <article className="sigma_post style-2">
                <div className="sigma_post-thumb">
                  <a state={{ id: 3 }} href="/tosol?id=3">
                    <img
                      src="women.jpg"
                      alt="post"
                      style={{ height: "265px" }}
                    />
                  </a>
                </div>
                <div className="sigma_post-body">
                  <div className="sigma_post-meta">
                    <a
                      state={{ id: 3 }}
                      href="/tosol?id=3"
                      className="sigma_post-date"
                    >
                      {" "}
                      <i className="far fa-calendar" /> {t("day")}
                    </a>
                  </div>
                  <h5>
                    {" "}
                    <a state={{ id: 3 }} href="/tosol?id=3">
                      {t("unshlag")}
                    </a>{" "}
                  </h5>
                </div>
              </article>
            </div>
            {/* Article End */}
            {/* Article Start */}
            <div className="col-lg-4 col-md-6">
              <article className="sigma_post style-2">
                <div className="sigma_post-thumb">
                  <a state={{ id: 2 }} href="/tosol?id=2">
                    <img
                      src="tosol_back.jpg"
                      style={{ height: "265px" }}
                      alt="post"
                    />
                  </a>
                </div>
                <div className="sigma_post-body">
                  <div className="sigma_post-meta">
                    <a
                      state={{ id: 2 }}
                      href="/tosol?id=2"
                      className="sigma_post-date"
                    >
                      {" "}
                      <i className="far fa-calendar" /> {t("tosol_day")}
                    </a>
                  </div>
                  <h5>
                    {" "}
                    <a state={{ id: 2 }} href="/tosol?id=2">
                      {t("tosol")} 2023/06/03
                    </a>{" "}
                  </h5>
                </div>
              </article>
            </div>
            {/* Article End */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
