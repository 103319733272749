import React, { createContext, useEffect, useState } from "react";
import { translations } from "./translations";

export const TranslationContext = createContext();

const TranslationProvider = ({ initialLanguage, children }) => {
  const [language, setLanguage] = useState(initialLanguage);

  useEffect(() => {
    localStorage.setItem("language", language);
  }, [language]);

  const t = (key) => {
    if (translations[language] && translations[language][key]) {
      return translations[language][key];
    }
    // If translation is not found, fallback to the key itself
    return key;
  };

  const value = {
    language,
    setLanguage,
    t,
  };

  return (
    <TranslationContext.Provider value={value}>
      {children}
    </TranslationContext.Provider>
  );
};

export default TranslationProvider;
